import { Helmet } from 'react-helmet-async';
import Planning from '../components/Reservation/Planning/Planning';
import styles from '../styles/pages/Reservation.module.scss';

const Reservation = () => (
  <>
    <Helmet>
      <title>One2One | Réservez votre séance</title>
      <meta name="description" content="Avec le planning, réservez votre séance en ligne en utilisant vos crédits. Accédez également à tous les renseignements concernant la séance de votre choix." />
    </Helmet>
    <header className={styles.header}>
      <h2 className={styles.title}>Réservez <span className={styles.pink}>votre séance maintenant</span></h2>
    </header>
    <Planning />
  </>
);

export default Reservation;
