import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Popup from 'react-customizable-popup';
import useProtected from '../../lib/hooks/useProtected';
import useAuth from '../../lib/hooks/useAuth';
import cross from '../../icons/cross.svg';
import styles from '../../styles/pages/NomsSeances.module.scss';

const NomsSeances = () => {
  useProtected();
  const defaultSeanceName = { name: '', studio: '' };
  const [currentSeanceName, setCurrentSeanceName] = useState(defaultSeanceName);
  const popupRef = useRef(null);
  const { user } = useAuth();
  const [studios, setStudios] = useState([]);
  const [seanceNames, setSeanceNames] = useState([]);
  const [adding, setAdding] = useState(false);

  const fetchData = async () => {
    const responseStudios = await fetch(
      `${process.env.REACT_APP_REQUEST_URL}/studios`,
      {
        method: 'GET',
      },
    );
    if (responseStudios.ok) {
      const data = await responseStudios.json();
      setStudios(data['hydra:member']);
    }
    const responseSeanceNames = await fetch(
      `${process.env.REACT_APP_REQUEST_URL}/seance_names`,
      {
        method: 'GET',
      },
    );
    if (responseSeanceNames.ok) {
      const data = await responseSeanceNames.json();
      setSeanceNames(data['hydra:member']);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleClick = (id) => {
    setAdding(false);
    setCurrentSeanceName(seanceNames.filter((name) => name.id === id)[0]);
    popupRef.current.open();
  };

  const handleChange = (event) => {
    setCurrentSeanceName((state) => ({ ...state, [event.target.name]: event.target.value }));
  };

  const handleAdd = () => {
    setAdding(true);
    setCurrentSeanceName(defaultSeanceName);
    popupRef.current.open();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const response = await fetch(
      `${process.env.REACT_APP_REQUEST_URL}/seance_names${adding ? '' : `/${currentSeanceName.id}`}`,
      {
        method: adding ? 'POST' : 'PATCH',
        body: JSON.stringify({
          name: currentSeanceName.name,
          studio: typeof currentSeanceName.studio === 'string' ? currentSeanceName.studio : currentSeanceName.studio['@id'],
        }),
        headers: {
          'Content-Type': adding ? 'application/json' : 'application/merge-patch+json',
          'Authorization': `Bearer ${user.token}`,
        },
      },
    );
    if (response.ok) {
      fetchData();
      popupRef.current.close();
      alert(`Le nom de séance a bien été ${adding ? 'ajouté' : 'modifié'} !`);
    } else {
      alert('Un problème est survenu.');
    }
  };

  const handleDelete = async (id) => {
    // eslint-disable-next-line no-restricted-globals
    const yes = confirm('Êtes-vous sûr de vouloir supprimer ce nom de séance ?');
    if (yes) {
      const response = await fetch(`${process.env.REACT_APP_REQUEST_URL}/seance_names/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${user.token}`,
        },
      });
      if (response.ok) {
        setCurrentSeanceName(defaultSeanceName);
        fetchData();
        popupRef.current.close();
        alert('Le nom de séance a bien été supprimée.');
      } else {
        alert('Un problème est survenu. Cette opération ne peut pas être effectuée si ce nom de séance est associé à une ou plusieurs séances.');
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>One2One | Gérer les noms des séances</title>
        <meta name="description" content="Il est possible d’ajouter un client en renseignant son nom, son prénom, sa date de naissance, son email mais aussi son numéro de téléphone et son adresse." />
      </Helmet>
      <h1 className={styles.titre}>Gestion des noms des séances</h1>
      <section className={styles.studiosContainer}>
        {studios?.map((studio) => (
          <div className={styles.studio} key={studio.id}>
            <h2 className={styles.title}>{studio.name}</h2>
            <ul className={styles.list}>
              {studio.seanceNames.map((name) => (
                <li key={name.id}>
                  <button className={styles.name} type="button" onClick={() => handleClick(name.id)}>{name.name}</button>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </section>
      <button className={styles.button} type="button" onClick={handleAdd}>Ajouter un nom de séance</button>
      <Popup
        className={styles.popup}
        ref={popupRef}
        position="modal"
      >
        <button className={styles.cross} type="button" data-close>
          <img src={cross} alt="" />
        </button>
        <form className={styles.form} onSubmit={handleSubmit}>
          <label className={styles.label} htmlFor="name">
            <p>Nom de séance</p>
            <input className={styles.input} name="name" id="name" type="text" value={currentSeanceName.name} placeholder="Nom de séance" onChange={handleChange} required />
          </label>
          <label className={styles.label} htmlFor="studio">
            <p>Pour quel studio</p>
            <select className={styles.input} name="studio" id="studio" value={currentSeanceName.studio['@id']} onChange={handleChange} required>
              <option value="">Choississez un studio</option>
              {studios?.map((studio) => (
                <option key={studio.id} value={studio['@id']}>{studio.name}</option>
              ))}
            </select>
          </label>
          <input className={styles.button} type="submit" value={adding ? 'Ajouter' : 'Modifier'} />
          {!adding && (
            <div className={styles.wrapperButton}>
              <button className={styles.smallButton} type="button" onClick={() => handleDelete(currentSeanceName.id)}>Supprimer ce nom de séance</button>
            </div>
          )}
        </form>
      </Popup>
    </>
  );
};
export default NomsSeances;
