import styles from '../styles/pages/Mentions.module.scss';

const PolitiqueConfidentialite = () => (
  <>
    <h1 className={styles.titre}>Politique de confidentialité</h1>
    <p className={styles.texte}>Les informations recueillies sur ce formulaire sont enregistrées dans un fichier informatisé par One2One pour la réservation de séances en ligne, la connexion à un espace client dédié à l'utilisateur. La base légale du traitement est l’exécution d'un contrat.</p>
    <p className={styles.texte}>Les données collectées seront communiquées au seul destinataire suivant : One2One.</p>
    <p className={styles.texte}>Les données sont conservées jusqu'à la demande de suppression de l'utilisateur.</p>
    <p className={styles.texte}>Vous pouvez accéder aux données vous concernant, les rectifier, demander leur effacement ou exercer votre droit à la limitation du traitement de vos données. Vous pouvez retirer à tout moment votre consentement au traitement de vos données.</p>
    <p className={styles.texte}>Consultez le site cnil.fr pour plus d’informations sur vos droits.</p>
    <p className={styles.texte}>Pour exercer ces droits ou pour toute question sur le traitement de vos données dans ce dispositif, vous pouvez contacter (le cas échéant, notre délégué à la protection des données ou le service chargé de l’exercice de ces droits) : one2one.larochelle@gmail.com, ONE2ONE, RUE DES TROIS FRÈRES, 17000 LA ROCHELLE, 07 85 62 69 25.</p>
    <p className={styles.texte}>Si vous estimez, après nous avoir contactés, que vos droits « Informatique et Libertés » ne sont pas respectés, vous pouvez adresser une réclamation à la CNIL.</p>
  </>
);

export default PolitiqueConfidentialite;
