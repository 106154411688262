import { useEffect, useRef, useState } from 'react';
import cross from '../../../icons/cross.svg';
import styles from './SearchClient.module.scss';

const SearchClient = ({ add }) => {
  const [search, setSearch] = useState('');
  const [clients, setClients] = useState([]);
  const [popupVisible, setPopupVisible] = useState(false);
  const allClients = useRef();

  const fetchData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_REQUEST_URL}/clients`,
      {
        method: 'GET',
      },
    );
    if (response.ok) {
      const data = await response.json();
      allClients.current = data['hydra:member'];
      setClients(data['hydra:member']);
    }
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    setClients(allClients.current.filter((client) => (
      client
        .lname
        .toLowerCase()
        .includes(event.target.value)
      || client
        .fname
        .toLowerCase()
        .includes(event.target.value)
    )));
  };

  const handleClick = (client) => {
    setSearch('');
    setPopupVisible(false);
    add(client);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={styles.wrapper}>
      <input
        className={styles.input}
        type="text"
        value={search}
        onChange={handleSearch}
        placeholder="Ajouter un participant"
        onFocus={() => setPopupVisible(true)}
      />
      <div className={`${styles.popup} ${!popupVisible ? styles.hidden : ''}`}>
        <h3 className={styles.title}>Ajouter un participant</h3>
        <button className={styles.cross} type="button" onClick={() => setPopupVisible(false)}>
          <img src={cross} alt="" />
        </button>
        <ul className={styles.list}>
          {clients?.map((client) => (
            <li key={client.id}>
              <button className={styles.button} type="button" onClick={() => handleClick(client)}>
                {client.lname} {client.fname}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SearchClient;
