import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from './useAuth';

const useProtected = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user.roles.includes('ROLE_SUPER_COACH')) {
      navigate('/back/planning');
    }
  }, []);
};

export default useProtected;
