import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Seance from '../components/Historique/Seance/Seance';
import useAuth from '../lib/hooks/useAuth';
import datePast from '../lib/utils/datePast';
import styles from '../styles/pages/Historique.module.scss';

const Historique = () => {
  const { user } = useAuth();
  const [coming, setComing] = useState([]);
  const [pasted, setPasted] = useState([]);

  const fetchData = async () => {
    const response = await fetch(`${process.env.REACT_APP_REQUEST_URL}/clients/${user.id}`);
    if (response.ok) {
      const newData = await response.json();
      setComing(newData.seances.filter((seance) => !datePast(seance.startdate)));
      setPasted(newData.seances.filter((seance) => datePast(seance.startdate)));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>One2One | Accédez à toutes l’historiques de vos séances</title>
        <meta name="description" content="L’historique de vos séances permet au client de la salle de sport One2One d’accéder à la liste des séances auxquelles les clients sont inscrits ainsi que la liste des séances déjà réalisées." />
      </Helmet>
      <div className={styles.wrapper}>
        <h1 className={styles.hero}>Historique des séances</h1>
        <h2 className={styles.subhero}>Retrouvez vos séances passées et à venir</h2>
        <h3 className={styles.title}>Séances à venir</h3>
        <ul className={styles.list}>
          {coming.length > 0 ? coming.map((seance) => (
            <li key={seance['@id']}>
              <Seance
                seance={seance}
                past={false}
                done={fetchData}
              />
            </li>
          )) : (
            <li>Aucune séance. <Link className={styles.link} to="/reservation">Voir les séances disponibles.</Link></li>
          )}
        </ul>
        <h3 className={styles.title}>Séances passées</h3>
        <ul className={styles.list}>
          {pasted.length > 0 ? pasted.map((seance) => (
            <li key={seance['@id']}>
              <Seance
                seance={seance}
                past
              />
            </li>
          )) : (
            <li>Aucune séance.</li>
          )}
        </ul>
      </div>
    </>
  );
};

export default Historique;
