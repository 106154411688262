import { useEffect, useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import arrow from '../../icons/arrow-return.svg';
import useProtected from '../../lib/hooks/useProtected';
import styles from '../../styles/pages/Form-clients-back.module.scss';
import useAuth from '../../lib/hooks/useAuth';

const ModificationsClientBack = () => {
  useProtected();
  const params = useParams();
  const [client, setClient] = useState({});
  const { user } = useAuth();

  const navigate = useNavigate();

  const formatDate = (date) => {
    const d = new Date(date);
    let month = d.getMonth() + 1;
    let day = d.getDate();
    const year = d.getFullYear();

    if (month < 10) {
      month = `0${month}`;
    }
    if (day < 10) {
      day = `0${day}`;
    }

    return [year, month, day].join('-');
  };

  const fetchData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_REQUEST_URL}/clients/${params.id}`,
      {
        method: 'GET',
      },
    );
    if (response.ok) {
      const data = await response.json();
      data.birthDate = formatDate(data.birthDate);

      setClient(data);
    } else {
      navigate('/back/liste-clients');
    }
  };

  const handleChange = (event) => {
    if (event.target.name === 'isVerified') {
      let isVerifiedValue = client.isVerified;
      if (event.target.value === '1') {
        isVerifiedValue = true;
      } else {
        isVerifiedValue = false;
      }
      setClient((state) => ({ ...state, [event.target.name]: isVerifiedValue }));
    } else {
      setClient((state) => ({ ...state, [event.target.name]: event.target.value }));
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    const body = {
      id: client.id,
      lname: client.lname,
      fname: client.fname,
      address: client.address,
      birthDate: client.birthDate,
      phone: client.phone,
      email: client.email,
      credits: parseInt(client.credits, 10),
      creditsOne: parseInt(client.creditsOne, 10),
      isVerified: client.isVerified,
    };

    const response = await fetch(`${process.env.REACT_APP_REQUEST_URL}/clients/${client.id}`, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`,
      },
    });
    if (response.ok) {
      navigate('/back/liste-clients');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>One2One | Modifications informations clients</title>
        <meta name="description" content="Vous pouvez modifier les informations de vos clients comme le nom, le prénom, la date de naissance, l’email mais aussi le numéro de téléphone ou l’adresse." />
      </Helmet>
      <Link to="/back/liste-clients" className={styles.lien}>
        <img src={arrow} alt="arrow" className={styles.image} />
        <p>Retourner à la liste des clients</p>
      </Link>
      <h1 className={styles.titre}>Modification des informations client</h1>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.nomComplet}>
          <label className={styles.label} htmlFor="lname">
            <p>Nom</p>
            <input className={styles.champ} type="text" name="lname" value={client?.lname} onChange={handleChange} />
          </label>
          <label className={styles.label} htmlFor="fname">
            <p>Prénom</p>
            <input className={styles.champ} type="text" name="fname" value={client?.fname} onChange={handleChange} />
          </label>
        </div>
        <div className={styles.naissanceTelephone}>
          <label className={styles.label} htmlFor="birthDate">
            <p>Date de naissance</p>
            <input className={styles.champ} type="date" name="birthDate" value={client?.birthDate} onChange={handleChange} />
          </label>
          <label className={styles.label} htmlFor="phone">
            <p>Numéro de téléphone</p>
            <input className={styles.champ} type="text" name="phone" value={client?.phone} onChange={handleChange} />
          </label>
        </div>
        <div className={styles.adresse}>
          <label className={styles.label} htmlFor="address">
            <p>Adresse</p>
            <input className={styles.champ} type="text" name="address" value={client?.address} onChange={handleChange} />
          </label>
        </div>
        <div className={styles.credits}>
          <label className={styles.label} htmlFor="email">
            <p>Email</p>
            <input className={styles.champ} type="email" name="email" value={client?.email} onChange={handleChange} />
          </label>
          <label className={styles.label} htmlFor="isVerified">
            <p>Compte activé ?</p>
            <select className={styles.champ} name="isVerified" value={client?.isVerified ? 1 : 0} onChange={handleChange}>
              <option value="1">Oui</option>
              <option value="0">Non</option>
            </select>
          </label>
        </div>
        <div className={styles.credits}>
          <label className={styles.label} htmlFor="credits">
            <p>Nombre de séances sur la carte</p>
            <input className={styles.champ} type="number" name="credits" min="0" value={client?.credits} onChange={handleChange} />
          </label>
          <label className={styles.label} htmlFor="creditsOne">
            <p>Nombre de séances ONE</p>
            <input className={styles.champ} type="number" name="creditsOne" min="0" value={client?.creditsOne} onChange={handleChange} />
          </label>
        </div>
        <input className={styles.button} type="submit" />
      </form>
    </>
  );
};
export default ModificationsClientBack;
