import { Helmet } from 'react-helmet-async';
import Studios from '../components/Common/Studios/Studios';
import styles from '../styles/pages/Activites.module.scss';

const Activites = () => (
  <>
    <Helmet>
      <title>One2One | Trois studios pour des entraînements personnalisés</title>
      <meta name="description" content="One2One vous propose les trois studios Biking 2.0, Cross-training et Wellness pour vos séances de sport personnalisées avec du matériel de qualité." />
    </Helmet>
    <header className={styles.header}>
      <div>
        <h1 className={styles.title}><strong>Activités</strong></h1>
        <p className={styles.text}>En nous inspirant des méthodes d'entraînements issues de la préparation physique des athlètes nous vous proposons un panel d'activités <span className={styles.pink}>(CROSS TRAINING, BIKING, WELLNESS)</span> vous permettant de développer, entretenir vos capacités physiques de manière efficace, durable et sécuritaire. Notre leitmotiv : <span className={styles.pink}>Sport, Santé et Bien Être !</span></p>
        <p className={styles.text}>Parce que <span className={styles.pink}>nos coachs sportifs</span>, tous diplômés d'état et expérimentés, formés à nos méthodes d’entraînement sont là pour vous accompagner sur le meilleur mix sportif vous atteindrez rapidement vos objectifs, quelque soit votre niveau. </p>
        <p className={styles.text}>PARCE QU’UN ENTRAÎNEMENT ENCADRÉ, <span className={styles.pink}>MAÎTRISÉ ET ACCESSIBLE</span> EST LE SECRET DE LA RÉUSSITE. ASSOCIEZ LES ACTIVITÉS <span className={styles.pink}>CROSS TRAINING, BIKING , WELLNESS,</span> COMBINEZ LEURS BIENFAITS, ET DÉVELOPPEZ VOS CAPACITÉS PHYSIQUES DE MANIÈRE DURABLE.</p>
      </div>
    </header>
    <Studios />
  </>
);

export default Activites;
