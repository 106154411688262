import styles from './PlanningItem.module.scss';

const PlanningItem = ({
  title,
  studio,
  coach,
  past,
  start,
  end,
  onClick,
  remainingPlaces,
  light = false,
}) => (
  <button className={`${styles.planningItem} ${light && styles.light} ${(past || remainingPlaces <= 0) ? styles.disabled : ''}`} onClick={onClick} type="button">
    <span className={`${styles.aside} ${studio}`}>
      <p className={styles.top}>{start}</p>
      <span className={styles.line} />
      <p className={styles.bottom}>{end}</p>
    </span>
    <span className={styles.main}>
      <h3 className={styles.title}>{studio}</h3>
      <p>{title}</p>
      <p className={styles.coach}>avec {coach}</p>
      <p className={styles.left}>{remainingPlaces < 0 ? '0' : remainingPlaces} place{remainingPlaces > 1 && 's'} restante{remainingPlaces > 1 && 's'}</p>
    </span>
  </button>
);

export default PlanningItem;
