/* eslint-disable no-nested-ternary */
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Popup from 'react-customizable-popup';
import useAuth from '../../../lib/hooks/useAuth';
import smallArrow from '../../../icons/small-arrow.svg';
import datePast from '../../../lib/utils/datePast';
import AdminPopup from '../AdminPopup/AdminPopup';
import PlanningItem from '../PlanningItem/PlanningItem';
import dateIsWithinOneDay from '../../../lib/utils/dateIsWithinOneDay';
import useMobile from '../../../lib/hooks/useMobile';
import cross from '../../../icons/cross.svg';
import styles from './Planning.module.scss';

const Planning = ({ light = false, admin = false }) => {
  const getMonday = (date) => {
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(date.setDate(diff));
  };

  const parseDate = (date) => {
    if (!date) {
      return {};
    }
    const newDate = new Date(date);

    /* Premier jour de la semaine est dimanche pour les américains */
    let day = newDate.getDay();
    if (day > 0) {
      day -= 1;
    } else {
      day = 6;
    }

    return {
      month: newDate.getMonth(),
      date: newDate.getDate(),
      day,
      hours: newDate.getHours(),
      minutes: newDate.getMinutes(),
    };
  };

  const [week, setWeek] = useState([]);
  const [weekName, setWeekName] = useState('');
  const currentWeek = useRef(getMonday(new Date()));
  const [selectedDay, setSelectedDay] = useState(parseDate(new Date()).day);
  const [currentSeance, setCurrentSeance] = useState();
  const [addingSeance, setAddingSeance] = useState(false);
  const { user, updateUser } = useAuth();
  const popupRef = useRef(null);
  const isMobile = useMobile();

  const days = [
    'Lundi',
    'Mardi',
    'Mercredi',
    'Jeudi',
    'Vendredi',
    'Samedi',
    'Dimanche',
  ];

  const addDays = (date, number) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + number);
    return newDate;
  };

  const addZero = (number) => (number < 10 ? `0${number}` : `${number}`);

  const parseHours = (d) => {
    const newDate = new Date(d);
    return `${addZero(newDate.getHours())}h${addZero(newDate.getMinutes())}`;
  };

  const fetchData = async (from) => {
    const seancesFrom = `${from.getFullYear()}-${from.getMonth() + 1}-${from.getDate()}`;
    const sunday = addDays(from, 7);
    const seancesTo = `${sunday.getFullYear()}-${sunday.getMonth() + 1}-${sunday.getDate()}`;

    const response = await fetch(
      `${process.env.REACT_APP_REQUEST_URL}/seances?startdate%5Bbefore%5D=${seancesTo}&startdate%5Bafter%5D=${seancesFrom}`,
      {
        method: 'GET',
      },
    );
    if (response.ok) {
      const data = await response.json();
      const newWeek = [{}, {}, {}, {}, {}, {}, {}];
      data['hydra:member'].forEach((seance) => {
        const { day } = parseDate(seance.startdate);
        if (!newWeek[day].seances) {
          newWeek[day].seances = [];
        }
        newWeek[day].seances.push(seance);
      });

      for (let i = 0; i < 7; i += 1) {
        // Print date correctly
        const newDay = addDays(new Date(from), i);
        newWeek[i].date = `${addZero(newDay.getDate())}/${addZero(newDay.getMonth() + 1)}`;

        // Check if the day is today
        const today = new Date();
        newWeek[i].isToday = newDay.getDate() === today.getDate() && newDay.getMonth() === today.getMonth();

        // Sort seances by their start
        if (newWeek[i].seances) {
          newWeek[i].seances = newWeek[i].seances.sort((previous, current) => (
            new Date(previous.startdate) < new Date(current.startdate) ? -1 : 1
          ));
        }
      }
      setWeek(newWeek);

      setWeekName(`Semaine du ${newWeek[0].date} au ${newWeek[6].date} ${sunday.getFullYear()}`);
    }
  };

  useEffect(() => {
    fetchData(currentWeek.current);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePreviousWeek = () => {
    const date = addDays(currentWeek.current, -7);
    currentWeek.current = date;
    fetchData(date);
  };

  const handleNextWeek = () => {
    const date = addDays(currentWeek.current, 7);
    currentWeek.current = date;
    fetchData(date);
  };

  const handlePreviousDay = () => {
    if (selectedDay > 0) {
      setSelectedDay((day) => day - 1);
    } else {
      setSelectedDay(6);
      handlePreviousWeek();
    }
  };

  const handleNextDay = () => {
    if (selectedDay < 6) {
      setSelectedDay((day) => day + 1);
    } else {
      setSelectedDay(0);
      handleNextWeek();
    }
  };

  const handleClickSeance = (seance) => {
    popupRef.current.open();
    setCurrentSeance(seance);
    setAddingSeance(false);
  };

  const handleAddSeance = () => {
    setCurrentSeance(null);
    popupRef.current.open();
    setAddingSeance(true);
  };

  const handlePopupDone = () => {
    fetchData(currentWeek.current);
    popupRef.current.close();
  };

  const isAlreadySub = (participants) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const participant of participants) {
      if (participant.id === user.id) {
        return true;
      }
    }
    return false;
  };

  const handleSubscribe = async (seance) => {
    if (seance.participants.length === 0 && dateIsWithinOneDay(seance.startdate)) {
      alert('Cette séance n\'aura pas lieu : elle commence dans moins de 24 heures et il n\'y a aucun inscrit.');
      return;
    }

    if (user.credits && user.credits > 0) {
      const updateSeance = await fetch(`${process.env.REACT_APP_REQUEST_URL}/seances/${seance.id}`, {
        method: 'PATCH',
        body: JSON.stringify({
          participants: [
            ...seance.participants.map((participant) => participant['@id']),
            user['@id'],
          ],
        }),
        headers: {
          'Content-Type': 'application/merge-patch+json',
          'Authorization': `Bearer ${user.token}`,
        },
      });
      if (updateSeance.ok) {
        const updateCredits = await fetch(`${process.env.REACT_APP_REQUEST_URL}/clients/${user.id}`, {
          method: 'PATCH',
          body: JSON.stringify({ credits: user.credits - 1 }),
          headers: {
            'Content-Type': 'application/merge-patch+json',
            'Authorization': `Bearer ${user.token}`,
          },
        });
        if (updateCredits.ok) {
          fetchData(currentWeek.current);
          updateUser({ credits: user.credits - 1 });
          alert('Vous avez bien été inscrit à cette séance !');
          const bodyMailInsc = JSON.stringify({
            email: user.email,
            fname: user.fname,
            date: `${addZero(parseDate(currentSeance?.startdate).date)}/${addZero(parseDate(currentSeance?.startdate).month + 1)}`,
            hour: parseHours(currentSeance?.startdate),
            type: seance.studio.name,
          });
          const responseMailInsc = await fetch(
            `${process.env.REACT_APP_MAIL_URL}/inscription-seance`,
            {
              method: 'POST',
              body: bodyMailInsc,
            },
          );
          if (responseMailInsc.ok) {
            alert('Vous avez reçu un mail de confirmation pour votre inscription.');
          } else {
            alert('Une erreur s\'est produite lors de l\'envoi du mail.');
          }
          popupRef.current.close();
        } else {
          alert('Une erreur s\'est produite lors du retrait de vos crédits.');
        }
      } else {
        alert('Une erreur s\'est produite lors de votre inscription à la séance.');
      }
    }
  };

  const handleUnsubscribe = async (seance) => {
    if (dateIsWithinOneDay(seance.startdate)) {
      alert('Vous ne pouvez pas vous désinscrire d\'une séance 24 heures avant le commencement de celle-ci.');
    } else {
      const updateSeance = await fetch(`${process.env.REACT_APP_REQUEST_URL}/seances/${seance.id}`, {
        method: 'PATCH',
        body: JSON.stringify({
          participants: [
            ...seance.participants.filter((participant) => participant.id !== user.id).map((participant) => participant['@id']),
          ],
        }),
        headers: {
          'Content-Type': 'application/merge-patch+json',
          'Authorization': `Bearer ${user.token}`,
        },
      });
      if (updateSeance.ok) {
        const updateCredits = await fetch(`${process.env.REACT_APP_REQUEST_URL}/clients/${user.id}`, {
          method: 'PATCH',
          body: JSON.stringify({ credits: user.credits + 1 }),
          headers: {
            'Content-Type': 'application/merge-patch+json',
            'Authorization': `Bearer ${user.token}`,
          },
        });
        if (updateCredits.ok) {
          fetchData(currentWeek.current);
          updateUser({ credits: user.credits + 1 });
          alert('Vous avez bien été désinscrit de cette séance !');
          const bodyMailDesinsc = JSON.stringify({
            email: user.email,
            fname: user.fname,
            date: `${addZero(parseDate(currentSeance?.startdate).date)}/${addZero(parseDate(currentSeance?.startdate).month + 1)}`,
            hour: parseHours(currentSeance?.startdate),
            type: seance.studio.name,
          });
          const responseMailDesinsc = await fetch(
            `${process.env.REACT_APP_MAIL_URL}/desinscription-seance`,
            {
              method: 'POST',
              body: bodyMailDesinsc,
            },
          );
          if (responseMailDesinsc.ok) {
            alert('Vous avez reçu un mail de confirmation pour votre désinscription.');
          } else {
            alert('Une erreur s\'est produite lors de l\'envoi du mail.');
          }
          popupRef.current.close();
        } else {
          alert('Une erreur s\'est produite.');
        }
      } else {
        alert('Une erreur s\'est produite.');
      }
    }
  };

  return (
    <section className={`${styles.planningContainer} ${light && styles.light}`}>
      <div className={styles.weekContainer}>
        <button className={styles.previous} onClick={handlePreviousWeek} type="button">
          <img src={smallArrow} alt="" />
        </button>
        <p className={styles.name}>{weekName}</p>
        <button className={styles.next} onClick={handleNextWeek} type="button">
          <img src={smallArrow} alt="" />
        </button>
      </div>
      <ul className={styles.planning} style={isMobile ? { transform: `translateX(calc(-100vw * ${selectedDay}))` } : {}}>
        {days.map((day, index) => (
          <li className={`${styles.day} ${week[index]?.isToday && styles.today}`} key={day}>
            <div className={styles.header}>
              {isMobile && (
                <button className={styles.previous} onClick={handlePreviousDay} type="button">
                  <img src={smallArrow} alt="" />
                </button>
              )}
              <h2 className={styles.date}>
                {day} {week[index]?.date}
              </h2>
              {isMobile && (
                <button className={styles.next} onClick={handleNextDay} type="button">
                  <img src={smallArrow} alt="" />
                </button>
              )}
            </div>
            <ul className={styles.list}>
              {week[index]?.seances?.map((seance) => (
                <li key={seance.id}>
                  <PlanningItem
                    title={seance.title.name}
                    studio={seance.studio.name}
                    coach={seance.coaches[0]?.fname}
                    past={datePast(seance.startdate)}
                    start={`${parseDate(seance.startdate).hours}h${addZero(parseDate(seance.startdate).minutes)}`}
                    end={`${parseDate(seance.enddate).hours}h${addZero(parseDate(seance.enddate).minutes)}`}
                    remainingPlaces={seance.nbMaxParticipants - seance.participants.length}
                    onClick={() => handleClickSeance(seance)}
                    light={light}
                  />
                </li>
              )) || (
                <li className={styles.text}>
                  Pas de séance pour ce jour.
                </li>
              )}
            </ul>
          </li>
        ))}
      </ul>
      {admin && (
        <button className={styles.button} type="button" onClick={handleAddSeance}>Ajouter un cours</button>
      )}
      <Popup
        className={`${styles.popup} ${light && styles.light}`}
        ref={popupRef}
        position="modal"
      >
        <button className={styles.cross} type="button" data-close>
          <img src={cross} alt="" />
        </button>
        {admin ? (
          <AdminPopup
            currentSeance={currentSeance}
            addingSeance={addingSeance}
            done={handlePopupDone}
          />
        ) : (
          <>
            <h3 className={styles.title}>Détails de la séance</h3>
            <p className={styles.text}>Studio<span className={styles.bold}>{currentSeance?.studio.name}</span></p>
            <p className={styles.text}>Nom de l'activité<span className={styles.bold}>{currentSeance?.title.name}</span></p>
            <p className={styles.text}>Coach<span className={styles.bold}>{currentSeance?.coaches[0].fname} {currentSeance?.coaches[0].lname}</span></p>
            <p className={styles.text}>Jour<span className={styles.bold}>{days[parseDate(currentSeance?.startdate).day]} {addZero(parseDate(currentSeance?.startdate).date)}/{addZero(parseDate(currentSeance?.startdate).month + 1)}</span></p>
            <p className={styles.text}>Heure de début<span className={styles.bold}>{parseDate(currentSeance?.startdate).hours}h{addZero(parseDate(currentSeance?.startdate).minutes)}</span></p>
            <p className={styles.text}>Heure de fin<span className={styles.bold}>{parseDate(currentSeance?.enddate).hours}h{addZero(parseDate(currentSeance?.enddate).minutes)}</span></p>
            <p className={styles.text}>Nombre de places restantes<span className={styles.bold}>{currentSeance && Math.max(0, currentSeance.nbMaxParticipants - currentSeance.participants.length)}</span></p>
            {user ? (
              user['@type'] === 'Client' && !datePast(currentSeance?.startdate) && currentSeance?.participants && (
                isAlreadySub(currentSeance?.participants) ? (
                  <>
                    <p className={styles.done}>Déjà inscrit(e)</p>
                    <button className={styles.button} type="button" onClick={() => handleUnsubscribe(currentSeance)}>Se désinscrire</button>
                  </>
                ) : (
                  currentSeance && currentSeance.nbMaxParticipants - currentSeance.participants.length > 0 ? (
                    user.credits > 0 ? (
                      <button className={styles.button} type="button" onClick={() => handleSubscribe(currentSeance)}>
                        Réserver ma séance ({user.credits} crédits restants)
                      </button>
                    ) : (
                      <>
                        <p className={styles.done}>Vous n'avez plus de crédits.</p>
                        <Link className={styles.button} to="/recharger">Recharger mes crédits</Link>
                      </>
                    )
                  ) : null
                )
              )
            ) : (
              <Link className={styles.button} to="/connexion">Connectez-vous pour pouvoir réserver</Link>
            )}
          </>
        )}
      </Popup>
    </section>
  );
};

export default Planning;
