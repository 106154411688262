import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import styles from '../styles/pages/Membres.module.scss';

const Concept = () => {
  const [collaborateurs, setCollaborateur] = useState([]);

  const tabCollaborateurs = [];

  const fetchData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_REQUEST_URL}/collaborateurs`,
      {
        method: 'GET',
      },
    );
    if (response.ok) {
      const data = await response.json();
      data['hydra:member'].forEach((collaborateur) => {
        tabCollaborateurs.push(collaborateur);
      });
      setCollaborateur(tabCollaborateurs);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>One2One | Nos collaborateurs sont à votre service</title>
        <meta name="description" content="Les collaborateurs One2One" />
      </Helmet>
      <header className={styles.header}>
        <div>
          <h1 className={styles.title}><strong>Collaborateurs</strong></h1>
          <h2 className={styles.subtitle}>Des collaborateurs <span className={styles.pink}>à votre service</span> pour vous accompagner</h2>
        </div>
      </header>
      { collaborateurs.map((collaborateur) => (
        <section key={collaborateur.id} className={styles.image} style={{ backgroundImage: `url(${process.env.REACT_APP_IMAGE_URL}/${collaborateur.photo})` }}>
          <h3 className={styles.name}>{collaborateur.fname} {collaborateur.lname}</h3>
          <Link className={styles.button} to={`/collaborateur/${collaborateur.id}`}>Voir le profil</Link>
        </section>
      ))}

    </>
  );
};

export default Concept;
