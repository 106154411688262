import styles from '../styles/pages/Mentions.module.scss';

const CGV = () => (
  <>
    <h1 className={styles.titre}>Conditions générales de vente</h1>
    <h2 className={styles.soustitre}>Caractéristiques essentielles des biens et/ou services</h2>
    <p className={styles.texte}>SÉANCES DE COACHING SPORTIF INDIVIDUELLE OU EN SMALL GROUP</p>
    <h2 className={styles.soustitre}>Prix TTC en euros</h2>
    <p className={styles.texte}>SÉANCE ONE INDIVIDUELLE  50€/ séance</p>
    <p className={styles.texte}>SÉANCE SMALL GROUP CROSS TRAINING ou BIKING ou WELLNESS</p>
    <p className={styles.texte}>UNITÉ 20€</p>
    <p className={styles.texte}>PACK 10 SÉANCES 140€</p>
    <p className={styles.texte}>PACK 20 SÉANCES 260€</p>
    <p className={styles.texte}>PACK 30 SÉANCES 360€</p>
    <h2 className={styles.soustitre}>Frais et date de livraison</h2>
    <p className={styles.texte}>AUCUN FRAIS / PAS DE LIVRASION </p>
    <h2 className={styles.soustitre}>Modalités de paiement : modes de paiement autorisés et question du retard de paiement.</h2>
    <p className={styles.texte}>CARTES BANCAIRES CB VISA MASTERCARD</p>
    <h2 className={styles.soustitre}>Droit de rétractation : délai et conditions pour annuler la commande</h2>
    <p className={styles.texte}>CONTACTER LE 07 85 62 69 25</p>
    <h2 className={styles.soustitre}>Garantie légale de conformité et garantie des vices cachés</h2>
    <p className={styles.texte}>CONTACTER LE 07 85 62 69 25</p>
    <h2 className={styles.soustitre}>Service après vente : coût de la communication à distance</h2>
    <p className={styles.texte}>COÛT CLASSIQUE D’APPEL DE L’OPÉRATEUR PAS DE SURTAXE</p>
    <h2 className={styles.soustitre}>Modalités de règlement des litiges : tribunal compétent et possibilité de recourir à un médiateur</h2>
    <p className={styles.texte}>CONTACTER LE 07 85 62 69 25</p>
  </>
);

export default CGV;
