import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import bcrypt from 'bcryptjs';
import useAuth from '../lib/hooks/useAuth';
import styles from '../styles/pages/Profil.module.scss';

const Profil = () => {
  const { user, updateUser, logout } = useAuth();
  const navigate = useNavigate();

  const handleSubmitInfos = async (event) => {
    event.preventDefault();

    const {
      fname,
      lname,
      birthDate,
      phone,
      email,
      address,
    } = event.target;

    const body = {
      fname: fname.value,
      lname: lname.value,
      birthDate: birthDate.value,
      phone: phone.value,
      email: email.value,
      address: address.value,
    };
    const response = await fetch(`${process.env.REACT_APP_REQUEST_URL}/${user['@type'] === 'Client' ? 'clients' : 'coaches'}/${user.id}`, {
      method: 'PATCH',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/merge-patch+json',
        'Authorization': `Bearer ${user.token}`,
      },
    });
    if (response.ok) {
      updateUser(body);
      alert('Vos informations ont bien été modifiées !');
    } else {
      alert('Une erreur s\'est produite.');
    }
  };

  const handleSubmitPassword = async (event) => {
    event.preventDefault();

    const {
      oldPassword,
      password,
      confirmPassword,
    } = event.target;

    if (password.value !== confirmPassword.value) {
      alert('Vous devez renseigner deux fois le même mot de passe.');
    } else {
      const response = await fetch(`${process.env.REACT_APP_REQUEST_URL}/login_check`, {
        method: 'POST',
        body: JSON.stringify({
          username: user.email,
          password: oldPassword.value,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        const hashedPassword = bcrypt.hashSync(password.value, bcrypt.genSaltSync(10));
        const res = await fetch(`${process.env.REACT_APP_REQUEST_URL}/coaches/${user.id}`, {
          method: 'PATCH',
          body: JSON.stringify({
            password: hashedPassword,
          }),
          headers: {
            'Content-Type': 'application/merge-patch+json',
            'Authorization': `Bearer ${user.token}`,
          },
        });
        if (res.ok) {
          alert('Votre mot de passe a bien été modifié !');
        } else {
          alert('Une erreur s\'est produite.');
        }
      }
    }
  };

  const handleDelete = async () => {
    const yes = confirm('Êtes-vous sûr de vouloir supprimer votre compte ? Toutes vos informations et vos crédits seront perdus.');
    if (yes) {
      const response = await fetch(`${process.env.REACT_APP_REQUEST_URL}/clients/${user.id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${user.token}`,
        },
      });
      if (response.ok) {
        navigate('/');
        logout();
      } else {
        alert('Une erreur est survenue.');
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>One2One | Modifiez votre profil One2One</title>
        <meta name="description" content="Modifiez vos données, changez votre mot de passe, votre email, votre adresse ou votre numéro de téléphone pour toujours garder votre profil One2One à jour." />
      </Helmet>
      <div className={styles.wrapper}>
        <h1 className={styles.title}>Modifier mes informations</h1>
        <form className={styles.form} onSubmit={handleSubmitInfos}>
          <div className={styles.horizontal}>
            <label className={styles.label} htmlFor="lname">
              Nom
              <input
                className={styles.input}
                name="lname"
                id="lname"
                type="text"
                placeholder="Votre nom"
                defaultValue={user.lname}
                required
              />
            </label>
            <label className={styles.label} htmlFor="fname">
              Prénom
              <input
                className={styles.input}
                name="fname"
                id="fname"
                type="text"
                placeholder="Votre prénom"
                defaultValue={user.fname}
                required
              />
            </label>
          </div>
          <label className={styles.label} htmlFor="birthDate">
            Date de naissance
            <input
              className={styles.input}
              name="birthDate"
              id="birthDate"
              type="date"
              defaultValue={user.birthDate.slice(0, 10)}
              required
            />
          </label>
          <label className={styles.label} htmlFor="phone">
            Numéro de téléphone
            <input
              className={styles.input}
              name="phone"
              id="phone"
              type="tel"
              placeholder="01234567"
              defaultValue={user.phone}
              required
            />
          </label>
          <label className={styles.label} htmlFor="email">
            Adresse mail
            <input
              className={styles.input}
              name="email"
              id="email"
              type="email"
              placeholder="exemple@gmail.com"
              defaultValue={user.email}
              required
            />
          </label>
          <label className={styles.label} htmlFor="address">
            Adresse
            <input
              className={styles.input}
              name="address"
              id="address"
              type="text"
              placeholder="35 Avenue du général..."
              defaultValue={user.address}
              required
            />
          </label>
          <input className={styles.button} type="submit" value="Modifier" />
        </form>
        {user['@type'] === 'Coach' && (
          <>
            <h2 className={styles.subtitle}>Modifier mon mot de passe</h2>
            <form className={styles.form} onSubmit={handleSubmitPassword}>
              <label className={styles.label} htmlFor="oldPassword">
                Ancien mot de passe
                <input
                  className={styles.input}
                  name="oldPassword"
                  id="oldPassword"
                  type="password"
                  placeholder="Votre ancien mot de passe"
                  required
                />
              </label>
              <label className={styles.label} htmlFor="password">
                Nouveau mot de passe
                <input
                  className={styles.input}
                  name="password"
                  id="password"
                  type="password"
                  placeholder="Votre nouveau mot de passe"
                  required
                />
              </label>
              <label className={styles.label} htmlFor="confirmPassword">
                Confirmation du nouveau mot de passe
                <input
                  className={styles.input}
                  name="confirmPassword"
                  id="confirmPassword"
                  type="password"
                  placeholder="Confirmez votre nouveau mot de passe"
                  required
                />
              </label>
              <input className={styles.button} type="submit" value="Modifier" />
            </form>
          </>
        )}
        {user['@type'] === 'Client' && (
          <button className={styles.deleteButton} type="button" onClick={handleDelete}>Supprimer définitivement mon compte</button>
        )}
      </div>
    </>
  );
};

export default Profil;
