import { useEffect, useState } from 'react';
import SearchClient from '../SearchClient/SearchClient';
import useDB from '../../../lib/hooks/useDB';
import useAuth from '../../../lib/hooks/useAuth';
import datePast from '../../../lib/utils/datePast';
import parseDateWithDashes from '../../../lib/utils/parseDateWithDashes';
import parseDateWithSlashes from '../../../lib/utils/parseDateWithSlashes';
import styles from './AdminPopup.module.scss';

const AdminPopup = ({ currentSeance, addingSeance, done }) => {
  const { user } = useAuth();
  const defaultContent = {
    title: '',
    studio: { '@id': 0 },
    coaches: [{ '@id': 0 }],
    startdate: '',
    enddate: '',
    nbMaxParticipants: 0,
    recurrence: false,
    recurrenceDate: '',
    editRecurrence: false,
  };
  const [content, setContent] = useState(defaultContent);
  const coaches = useDB('coaches');
  const studios = useDB('studios');
  const seanceNames = useDB('seance_names');

  const parseDate = (date) => {
    if (!date) {
      return {};
    }
    const newDate = new Date(date);

    /* Premier jour de la semaine est dimanche pour les américains */
    let day = newDate.getDay();
    if (day > 0) {
      day -= 1;
    } else {
      day = 6;
    }

    return {
      month: newDate.getMonth(),
      date: newDate.getDate(),
      day,
      hours: newDate.getHours(),
      minutes: newDate.getMinutes(),
    };
  };

  const addZero = (number) => (number < 10 ? `0${number}` : `${number}`);

  const parseHours = (d) => {
    const newDate = new Date(d);
    return `${addZero(newDate.getHours())}h${addZero(newDate.getMinutes())}`;
  };

  const getNextWeek = (date) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + 7);

    return parseDateWithDashes(newDate);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let recurrenceId = 0;
    if (content.recurrence && content.recurrenceDate) {
      const resRecurrence = await fetch(`${process.env.REACT_APP_REQUEST_URL}/recurrences`, {
        method: 'POST',
        body: JSON.stringify({
          startDate: content.startdate,
          endDate: content.enddate,
        }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`,
        },
      });
      if (resRecurrence.ok) {
        const data = await resRecurrence.json();
        recurrenceId = data.id;
      }
    }

    const body = { ...content };
    delete body.recurrenceDate;
    delete body.editRecurrence;

    if (typeof body.studio !== 'string') {
      body.studio = body.studio['@id'];
    }
    if (typeof body.title !== 'string') {
      body.title = body.title['@id'];
    }
    body.coaches = typeof body.coaches === 'string' ? new Array(body.coaches) : body.coaches.map((coach) => coach['@id']);
    if (addingSeance) {
      body.participants = [];
      body.recurrence = recurrenceId ? `/api/recurrences/${recurrenceId}` : null;
    } else {
      body.participants = body.participants.map((participant) => participant['@id']);
    }
    body.nbMaxParticipants = parseInt(body.nbMaxParticipants, 10);

    if (addingSeance) { // If the admin add a seance, if it's part of a recurrence or not
      let { startdate, enddate } = body;
      const allPromises = [];
      let firstDone = false;
      do {
        if (firstDone) {
          startdate = getNextWeek(startdate);
          enddate = getNextWeek(enddate);
        } else {
          firstDone = true;
        }
        const response = fetch(`${process.env.REACT_APP_REQUEST_URL}/seances`, {
          method: 'POST',
          body: JSON.stringify({
            ...body,
            startdate,
            enddate,
          }),
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${user.token}`,
          },
        });
        allPromises.push(response);
      } while (new Date(startdate) < new Date(content.recurrenceDate));
      const values = await Promise.all(allPromises);
      if (values.reduce((previous, current) => (previous ? current.ok : false))) {
        alert(values.length > 1 ? 'Les séances ont bien été ajoutées !' : 'La séance a bien été ajoutée !');
        done();
      }
    } else if (content.editRecurrence) { // If the admin wants to modify all the seances of a recurrence
      const response = await fetch(`${process.env.REACT_APP_REQUEST_URL}/recurrences/${body.recurrence.id}`);
      if (response.ok) {
        const data = await response.json();
        const sortedSeances = data.seances.sort((previous, current) => (new Date(current.startdate) < new Date(previous.startdate) ? 1 : -1));
        let { startdate, enddate } = sortedSeances[0];

        startdate = new Date(startdate);
        startdate.setHours(new Date(body.startdate).getHours(), new Date(body.startdate).getMinutes());
        startdate = parseDateWithDashes(startdate);

        enddate = new Date(enddate);
        enddate.setHours(new Date(body.enddate).getHours(), new Date(body.enddate).getMinutes());
        enddate = parseDateWithDashes(enddate);

        const allPromises = [];
        sortedSeances.forEach((seance, index) => {
          if (index > 0) {
            startdate = getNextWeek(startdate);
            enddate = getNextWeek(enddate);
          }
          if (!datePast(startdate)) {
            const res = fetch(`${process.env.REACT_APP_REQUEST_URL}/seances/${seance.id}`, {
              method: 'PATCH',
              body: JSON.stringify({
                coaches: body.coaches,
                nbMaxParticipants: body.nbMaxParticipants,
                studio: body.studio,
                title: body.title,
                startdate,
                enddate,
              }),
              headers: {
                'Content-Type': 'application/merge-patch+json',
                'Authorization': `Bearer ${user.token}`,
              },
            });
            allPromises.push(res);
          }
        });
        const values = await Promise.all(allPromises);
        if (values.reduce((previous, current) => (previous ? current.ok : false))) {
          alert('Les séances ont bien été modifiées !');
          done();
        }
      }
    } else { // If the admin is modifying only one seance
      const response = await fetch(`${process.env.REACT_APP_REQUEST_URL}/seances/${content.id}`, {
        method: 'PUT',
        body: JSON.stringify({ ...body, recurrence: body.recurrence['@id'] }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`,
        },
      });
      if (response.ok) {
        alert('La séance a bien été modifiée !');
        done();
      }
    }
  };

  const handleChange = (event) => {
    setContent((state) => ({ ...state, [event.target.name]: event.target.value }));
  };

  const handleChangeCheckbox = (event) => {
    setContent((state) => ({ ...state, [event.target.name]: event.target.checked }));
  };

  const handleDelete = async (id) => {
    // eslint-disable-next-line no-restricted-globals
    const yes = confirm('Êtes-vous sûr de vouloir supprimer cette séance ?');
    if (yes) {
      const response = await fetch(`${process.env.REACT_APP_REQUEST_URL}/seances/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${user.token}`,
        },
      });
      if (response.ok) {
        alert('La séance a bien été supprimée.');
        setContent(defaultContent);
        done();
      }
    }
  };

  const handleDeleteRecurrence = async (id) => {
    // eslint-disable-next-line no-restricted-globals
    const yes = confirm('Êtes-vous sûr de vouloir supprimer cette séance ainsi que toutes celles qui font partie de cette récurrence ?');
    if (yes) {
      const response = await fetch(`${process.env.REACT_APP_REQUEST_URL}/recurrences/${id}`);
      if (response.ok) {
        const data = await response.json();
        const allPromises = data.seances.map((seance) => {
          const res = fetch(`${process.env.REACT_APP_REQUEST_URL}/seances/${seance.id}`, {
            method: 'DELETE',
            headers: {
              'Authorization': `Bearer ${user.token}`,
            },
          });
          return res;
        });
        const values = await Promise.all(allPromises);
        if (values.reduce((previous, current) => (previous ? current.ok : false))) {
          alert('Les séances ont bien été supprimées !');
          done();
        }
      }
    }
  };

  const handleAddParticipant = async (client) => {
    const response = await fetch(`${process.env.REACT_APP_REQUEST_URL}/seances/${content.id}`, {
      method: 'PATCH',
      body: JSON.stringify({
        participants: [
          ...content.participants.map((participant) => participant['@id']),
          client['@id'],
        ],
      }),
      headers: {
        'Content-Type': 'application/merge-patch+json',
        'Authorization': `Bearer ${user.token}`,
      },
    });
    if (response.ok) {
      const updateCredits = await fetch(`${process.env.REACT_APP_REQUEST_URL}/clients/${client.id}`, {
        method: 'PATCH',
        body: JSON.stringify({ credits: client.credits - 1 }),
        headers: {
          'Content-Type': 'application/merge-patch+json',
          'Authorization': `Bearer ${user.token}`,
        },
      });
      if (updateCredits.ok) {
        alert('Cet utilisateur a bien été ajouté à cette séance !');
        const getSeanceAjout = await fetch(`${process.env.REACT_APP_REQUEST_URL}/seances/${content.id}`);
        if (getSeanceAjout.ok) {
          const seanceData = await getSeanceAjout.json();
          const bodyMailAjout = JSON.stringify({
            email: client.email,
            fname: client.fname,
            date: `${addZero(parseDate(seanceData.startdate).date)}/${addZero(parseDate(seanceData.startdate).month + 1)}`,
            hour: parseHours(seanceData.startdate),
            type: seanceData.studio.name,
          });
          const responseMailAjout = await fetch(
            `${process.env.REACT_APP_MAIL_URL}/ajout-seance`,
            {
              method: 'POST',
              body: bodyMailAjout,
            },
          );
          if (responseMailAjout.ok) {
            alert('Le client que vous venez d\'inscrire a reçu un mail qui confirme son inscription à cette séance.');
          } else {
            alert('Une erreur s\'est produite lors de l\'envoi du mail.');
          }
        }
        done();
      } else {
        alert('Une erreur est survenue lors du retrait des crédits du client.');
      }
    } else {
      alert('Une erreur est survenue lors de l\'inscription du client à la séance.');
    }
  };

  const handleUnsubscribe = async (client) => {
    const updateSeance = await fetch(`${process.env.REACT_APP_REQUEST_URL}/seances/${content.id}`, {
      method: 'PATCH',
      body: JSON.stringify({
        participants: [
          ...content.participants.filter((participant) => participant.id !== client.id).map((participant) => participant['@id']),
        ],
      }),
      headers: {
        'Content-Type': 'application/merge-patch+json',
        'Authorization': `Bearer ${user.token}`,
      },
    });
    if (updateSeance.ok) {
      const getClient = await fetch(`${process.env.REACT_APP_REQUEST_URL}/clients/${client.id}`);
      if (getClient.ok) {
        const clientData = await getClient.json();
        const updateCredits = await fetch(`${process.env.REACT_APP_REQUEST_URL}/clients/${client.id}`, {
          method: 'PATCH',
          body: JSON.stringify({ credits: clientData.credits + 1 }),
          headers: {
            'Content-Type': 'application/merge-patch+json',
            'Authorization': `Bearer ${user.token}`,
          },
        });
        if (updateCredits.ok) {
          alert('Vous avez bien désinscrit ce client de cette séance !');
          const getSeanceRetrait = await fetch(`${process.env.REACT_APP_REQUEST_URL}/seances/${content.id}`);
          if (getSeanceRetrait.ok) {
            const seanceDataRetrait = await getSeanceRetrait.json();
            const bodyMailRetrait = JSON.stringify({
              email: clientData.email,
              fname: clientData.fname,
              date: `${addZero(parseDate(seanceDataRetrait.startdate).date)}/${addZero(parseDate(seanceDataRetrait.startdate).month + 1)}`,
              hour: parseHours(seanceDataRetrait.startdate),
              type: seanceDataRetrait.studio.name,
            });
            const responseMailAjout = await fetch(
              `${process.env.REACT_APP_MAIL_URL}/retrait-seance`,
              {
                method: 'POST',
                body: bodyMailRetrait,
              },
            );
            if (responseMailAjout.ok) {
              alert('Le client que vous venez de désinscrire a reçu un mail qui confirme sa désinscription à cette séance.');
            } else {
              alert('Une erreur s\'est produite lors de l\'envoi du mail.');
            }
          }
          done();
        } else {
          alert('Une erreur est survenue lors de l\'ajout du crédit au client.');
        }
      } else {
        alert('Une erreur est survenue lors de la désinscription du client à la séance.');
      }
    } else {
      alert('Une erreur s\'est produite.');
    }
  };

  useEffect(() => {
    if (currentSeance && Object.keys(currentSeance).length > 0) {
      const newContent = { ...defaultContent, ...currentSeance };
      if (newContent.startdate?.includes('+') && newContent.enddate?.includes('+')) {
        [newContent.startdate] = newContent.startdate.split('+');
        [newContent.enddate] = newContent.enddate.split('+');
      }
      setContent(newContent);
    } else {
      setContent(defaultContent);
    }
  }, [currentSeance]);

  return (
    <>
      <h3 className={styles.title}>{addingSeance ? 'Ajouter un cours' : 'Modifier ce cours'}</h3>
      <form className={styles.form} onSubmit={handleSubmit}>
        <label className={styles.label} htmlFor="studio">
          <p>Type de cours</p>
          <select className={styles.input} name="studio" id="studio" value={content?.studio['@id']} onChange={handleChange} required>
            <option value="">Choississez un studio</option>
            {studios?.map((studio) => (
              <option key={studio.id} value={studio['@id']}>{studio.name}</option>
            ))}
          </select>
        </label>
        <label className={styles.label} htmlFor="title">
          <p>Nom du cours</p>
          <select className={styles.input} name="title" id="title" value={content?.title['@id']} onChange={handleChange} required>
            <option value="">Choississez un nom de séance</option>
            {seanceNames?.filter((name) => name.studio['@id'] === content?.studio || name.studio['@id'] === content?.studio['@id']).map((name) => (
              <option key={name.id} value={name['@id']}>{name.name}</option>
            ))}
          </select>
          {/* <input className={styles.input} name="title" id="title" type="text" value={content?.title} placeholder="Le nom du cours" onChange={handleChange} required /> */}
        </label>
        <label className={styles.label} htmlFor="coaches">
          <p>Coach</p>
          <select className={styles.input} name="coaches" id="coaches" value={content?.coaches && content?.coaches[0]['@id']} onChange={handleChange} required>
            <option value="">Choississez un coach</option>
            {coaches?.map((coach) => (
              <option key={coach.id} value={coach['@id']}>{coach.lname} {coach.fname}</option>
            ))}
          </select>
        </label>
        <label className={styles.label} htmlFor="startdate">
          <p>Date et heure de début</p>
          <input className={styles.input} name="startdate" id="startdate" type="datetime-local" value={content?.startdate} onChange={handleChange} required />
        </label>
        <label className={styles.label} htmlFor="enddate">
          <p>Date et heure de fin</p>
          <input className={styles.input} name="enddate" id="enddate" type="datetime-local" value={content?.enddate} onChange={handleChange} required />
        </label>
        <label className={styles.label} htmlFor="nbMaxParticipants">
          <p>Nombre maximum de participants</p>
          <input className={styles.input} name="nbMaxParticipants" id="nbMaxParticipants" type="number" value={content?.nbMaxParticipants} onChange={handleChange} required />
        </label>
        {addingSeance && (
          <>
            <hr className={styles.separator} />
            <label className={styles.labelCheckbox} htmlFor="recurrence">
              <input className={styles.checkbox} name="recurrence" id="recurrence" type="checkbox" checked={content?.recurrence} onChange={handleChangeCheckbox} />
              <p>Ajouter une récurrence</p>
            </label>
            {content.recurrence && (
              <label className={styles.label} htmlFor="recurrenceDate">
                <p>Date de fin de la récurrence (une séance sera ajoutée toutes les semaines jusqu'à cette date)</p>
                <input className={styles.input} name="recurrenceDate" id="recurrenceDate" type="date" value={content?.recurrenceDate} onChange={handleChange} />
              </label>
            )}
          </>
        )}
        {!addingSeance && content?.recurrence && (
          <label className={styles.labelCheckbox} htmlFor="editRecurrence">
            <input className={styles.checkbox} name="editRecurrence" id="editRecurrence" type="checkbox" checked={content?.editRecurrence} onChange={handleChangeCheckbox} />
            <p>Modifier toutes les séances de la même récurrence (jusqu'au {content?.recurrence?.endDate && parseDateWithSlashes(content.recurrence.endDate)})</p>
          </label>
        )}
        <input className={styles.button} type="submit" value={addingSeance ? 'Ajouter' : 'Modifier'} />
        {!addingSeance && (
          <div className={styles.wrapperButton}>
            <button className={styles.smallButton} type="button" onClick={() => handleDelete(content.id)}>Supprimer cette séance</button>
            {content?.recurrence && (
              <button className={styles.smallButton} type="button" onClick={() => handleDeleteRecurrence(content.recurrence.id)}>Supprimer cette récurrence</button>
            )}
          </div>
        )}
      </form>
      {content?.participants && (
        <>
          <h3 className={styles.smallTitle}>Participants à cette séance</h3>
          <ul className={styles.list}>
            {content.participants.length > 0 ? content.participants.map((participant) => (
              <li className={styles.item} key={participant.id}>
                <p>{participant.lname} {participant.fname}</p>
                <button className={styles.button} type="button" onClick={() => handleUnsubscribe(participant)}>Désinscrire</button>
              </li>
            )) : (
              <p>Aucun participant.</p>
            )}
          </ul>
        </>
      )}
      {!addingSeance && (
        <SearchClient add={handleAddParticipant} />
      )}
    </>
  );
};

export default AdminPopup;
