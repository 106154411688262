import { Link } from 'react-router-dom';
import biking from '../../../images/porte-biking.jpg';
import cross from '../../../images/porte-cross.jpg';
import wellness from '../../../images/porte-wellness.jpg';
import biking2 from '../../../images/biking-free.jpg';
import cross2 from '../../../images/cross.jpg';
import wellness2 from '../../../images/wellness-free.jpg';
import logoBiking from '../../../images/studio-biking.png';
import logoCrossTraining from '../../../images/studio-cross-training.png';
import logoWellness from '../../../images/studio-wellness.png';
import styles from './Studios.module.scss';

const Studios = ({ doors }) => (
  <ul className={styles.studios}>
    <li className={styles.item}>
      <figure className={styles.figure}>
        <img className={styles.image} src={doors ? biking : biking2} alt="" />
        <div className={styles.gradient} />
        <figcaption className={styles.content}>
          <img className={styles.logo} src={logoBiking} alt="" />
          <Link className={styles.button} to="/activite/biking">Découvrir</Link>
        </figcaption>
      </figure>
    </li>
    <li className={styles.item}>
      <figure className={styles.figure}>
        <img className={styles.image} src={doors ? cross : cross2} alt="" />
        <div className={styles.gradient} />
        <figcaption className={styles.content}>
          <img className={styles.logo} src={logoCrossTraining} alt="" />
          <Link className={styles.button} to="/activite/cross-training">Découvrir</Link>
        </figcaption>
      </figure>
    </li>
    <li className={styles.item}>
      <figure className={styles.figure}>
        <img className={styles.image} src={doors ? wellness : wellness2} alt="" />
        <div className={styles.gradient} />
        <figcaption className={styles.content}>
          <img className={styles.logo} src={logoWellness} alt="" />
          <Link className={styles.button} to="/activite/wellness">Découvrir</Link>
        </figcaption>
      </figure>
    </li>
  </ul>
);

export default Studios;
