import { useState } from 'react';
import useAuth from '../../../lib/hooks/useAuth';
import dateIsWithinOneDay from '../../../lib/utils/dateIsWithinOneDay';
import arrow from '../../../icons/small-arrow.svg';
import styles from './Seance.module.scss';

const Seance = ({
  seance,
  past,
  done,
}) => {
  const [open, setOpen] = useState(false);
  const { user, updateUser } = useAuth();

  const addZero = (number) => (number < 10 ? `0${number}` : `${number}`);

  const parseDate = (date) => {
    if (!date) {
      return {};
    }
    const newDate = new Date(date);

    /* Premier jour de la semaine est dimanche pour les américains */
    let day = newDate.getDay();
    if (day > 0) {
      day -= 1;
    } else {
      day = 6;
    }

    return {
      month: newDate.getMonth(),
      date: newDate.getDate(),
      day,
      hours: newDate.getHours(),
      minutes: newDate.getMinutes(),
    };
  };

  const days = [
    'Lundi',
    'Mardi',
    'Mercredi',
    'Jeudi',
    'Vendredi',
    'Samedi',
    'Dimanche',
  ];

  const parseHours = (d) => {
    const newDate = new Date(d);
    return `${addZero(newDate.getHours())}h${addZero(newDate.getMinutes())}`;
  };

  const handleUnsubscribe = async () => {
    if (dateIsWithinOneDay(seance.startdate)) {
      alert('Vous ne pouvez pas vous désinscrire d\'une séance 24 heures avant le commencement de celle-ci.');
    } else {
      const getSeance = await fetch(`${process.env.REACT_APP_REQUEST_URL}/seances/${seance.id}`);
      if (getSeance.ok) {
        const seanceData = await getSeance.json();

        const updateSeance = await fetch(`${process.env.REACT_APP_REQUEST_URL}/seances/${seance.id}`, {
          method: 'PATCH',
          body: JSON.stringify({
            participants: [
              ...seanceData.participants.filter((participant) => participant.id !== user.id).map((participant) => participant['@id']),
            ],
          }),
          headers: {
            'Content-Type': 'application/merge-patch+json',
            'Authorization': `Bearer ${user.token}`,
          },
        });
        if (updateSeance.ok) {
          const updateCredits = await fetch(`${process.env.REACT_APP_REQUEST_URL}/clients/${user.id}`, {
            method: 'PATCH',
            body: JSON.stringify({ credits: user.credits + 1 }),
            headers: {
              'Content-Type': 'application/merge-patch+json',
              'Authorization': `Bearer ${user.token}`,
            },
          });
          if (updateCredits.ok) {
            done();
            updateUser({ credits: user.credits + 1 });
            alert('Vous avez bien été désinscrit de cette séance !');
            const bodyMailDesinsc = JSON.stringify({
              email: user.email,
              fname: user.fname,
              date: `${addZero(parseDate(seanceData.startdate).date)}/${addZero(parseDate(seanceData.startdate).month + 1)}`,
              hour: parseHours(seanceData.startdate),
              type: seanceData.studio.name,
            });
            const responseMailDesinsc = await fetch(
              `${process.env.REACT_APP_MAIL_URL}/desinscription-seance`,
              {
                method: 'POST',
                body: bodyMailDesinsc,
              },
            );
            if (responseMailDesinsc.ok) {
              alert('Vous avez reçu un mail de confirmation pour votre désinscription.');
            } else {
              alert('Une erreur s\'est produite lors de l\'envoi du mail.');
            }
          } else {
            alert('Une erreur s\'est produite lors de votre désinscritpion à la séance.');
          }
        } else {
          alert('Une erreur s\'est produite.');
        }
      }
    }
  };

  return (
    <section className={`${styles.seance} ${open && styles.open}`}>
      <header
        className={styles.header}
        onClick={() => setOpen((state) => !state)}
        role="button"
        aria-hidden
      >
        <span className={`${styles.color} ${seance.studio.name}`} />
        <h3 className={styles.title}>{seance.title.name} le {days[(parseDate(seance.startdate).day)]} {addZero(parseDate(seance.startdate).date)}/{addZero(parseDate(seance.startdate).month + 1)}</h3>
        <button className={styles.arrow} type="button">
          <img src={arrow} alt="" />
        </button>
      </header>
      <article className={styles.content}>
        <div className={styles.contentWrapper}>
          <p><span className={styles.bold}>Studio :</span> {seance.studio.name}</p>
          <p><span className={styles.bold}>Nom de l&apos;activité :</span> {seance.title.name}</p>
          <p><span className={styles.bold}>Coach :</span> {seance.coaches[0].fname} {seance.coaches[0].lname}</p>
          <p><span className={styles.bold}>Horaire de début :</span> {parseHours(seance.startdate)}</p>
          <p><span className={styles.bold}>Horaire de fin :</span> {parseHours(seance.enddate)}</p>
          {!past && (
            <button className={styles.button} type="button" onClick={handleUnsubscribe}>Se désincrire</button>
          )}
        </div>
      </article>
    </section>
  );
};

export default Seance;
