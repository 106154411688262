import { Link } from 'react-router-dom';
import useAuth from '../../../lib/hooks/useAuth';
import styles from './ClientSpace.module.scss';

const ClientSpace = ({ closeMenu }) => {
  const { user, logout } = useAuth();

  const alertPaiement = () => {
    alert('La partie paiement n\'est pas disponible actuellement. Veuillez prendre contact avec un administrateur de One2One pour obtenir des séances.');
  };

  if (user['@type'] === 'Client') {
    return (
      <div className={styles.wrapper}>
        <p className={styles.toptitle}>Connecté en tant que</p>
        <h3 className={styles.title}>{user.fname} {user.lname}</h3>
        <p className={styles.subtitle}>Séances créditées</p>
        <h4 className={styles.credits}>{user.credits}</h4>
        {/* <Link to="/recharger" onClick={closeMenu}><button className={styles.add} type="button">Ajouter</button></Link> */}
        <button className={styles.add} type="button" onClick={alertPaiement}>Ajouter</button>
        <Link className={styles.button} to="/historique" onClick={closeMenu}>Historique de mes séances</Link>
        <Link className={styles.emptyButton} to="/profil" onClick={closeMenu}>Modifier mes informations</Link>
        <button className={styles.logout} type="button" onClick={logout}>Se déconnecter</button>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <p className={styles.toptitle}>Connecté en tant que</p>
      <h3 className={styles.title}>{user.fname} {user.lname}</h3>
      <Link className={styles.button} to="/back/planning">Aller à l&apos;administration</Link>
      <Link className={styles.emptyButton} to="/profil" onClick={closeMenu}>Modifier mes informations</Link>
      <button className={styles.logout} type="button" onClick={logout}>Se déconnecter</button>
    </div>
  );
};

export default ClientSpace;
