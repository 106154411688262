import { Helmet } from 'react-helmet-async';
import Planning from '../../components/Reservation/Planning/Planning';

const PlanningPage = () => (
  <>
    <Helmet>
      <title>One2One | Planning backoffice</title>
      <meta name="description" content="Ce planning permettra aux coachs de modifier, ajouter ou supprimer des séances. Chaque changement se répercute sur le planning du site afin que les clients réservent." />
    </Helmet>
    <div>
      <h2>Planning</h2>
      <Planning light admin />
    </div>
  </>
);

export default PlanningPage;
