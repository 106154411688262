import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useMobile from '../../../lib/hooks/useMobile';
import useAuth from '../../../lib/hooks/useAuth';
import logo from '../../../icons/logo-back.svg';
import cross from '../../../icons/cross.svg';
import menu from '../../../icons/menu.svg';
import planningIcon from '../../../icons/planning.svg';
import coach from '../../../icons/coach.svg';
import tag from '../../../icons/tag.svg';
import clients from '../../../icons/clients.svg';
import money from '../../../icons/money.svg';
import stripeIcon from '../../../icons/stripe.svg';
import homeIcon from '../../../icons/home.svg';
import styles from './BackLayout.module.scss';

const BackLayout = ({ children }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const isMobile = useMobile();

  useEffect(() => {
    if (!user || user['@type'] !== 'Coach') {
      navigate('/');
    }
  }, []);

  const handleClose = () => setMenuOpen(false);

  if (user) {
    return (
      <div className={styles.wrapper}>
        {isMobile && (
          <nav className={styles.mobileNavbar}>
            <img className={styles.logo} src={logo} alt="" />
            <button
              className={styles.button}
              type="button"
              onClick={() => setMenuOpen(true)}
            >
              <img className={styles.icon} src={menu} alt="" />
            </button>
          </nav>
        )}
        <nav className={`${styles.menu} ${isMobile && styles.mobile} ${menuOpen && styles.open}`}>
          <header className={styles.header}>
            <img className={styles.logo} src={logo} alt="" />
            <h1 className={`${styles.hero} ${styles.wrapped}`}>Administration</h1>
            {isMobile && (
              <button
                className={styles.button}
                type="button"
                onClick={() => setMenuOpen(false)}
              >
                <img className={styles.icon} src={cross} alt="" />
              </button>
            )}
          </header>
          <div className={styles.connected}>
            <span className={styles.indicator} />
            <h2 className={`${styles.text} ${styles.wrapped}`}>
              Connecté en tant que
              <span className={styles.bigger}>{user.fname} {user.lname}</span>
            </h2>
          </div>
          <ul className={styles.list}>
            <li>
              <Link className={styles.link} to="/back/planning" onClick={handleClose}>
                <img src={planningIcon} alt="" />
                <span className={`${styles.text} ${styles.wrapped}`}>Planning</span>
              </Link>
            </li>
            {user?.roles?.includes('ROLE_SUPER_COACH') && (
              <>
                <li>
                  <Link className={styles.link} to="/back/noms-seances" onClick={handleClose}>
                    <img src={tag} alt="" />
                    <span className={`${styles.text} ${styles.wrapped}`}>Noms des séances</span>
                  </Link>
                </li>
                <li>
                  <Link className={styles.link} to="/back/liste-clients" onClick={handleClose}>
                    <img src={clients} alt="" />
                    <span className={`${styles.text} ${styles.wrapped}`}>Liste des clients</span>
                  </Link>
                </li>
                <li>
                  <Link className={styles.link} to="/back/liste-coachs" onClick={handleClose}>
                    <img src={coach} alt="" />
                    <span className={`${styles.text} ${styles.wrapped}`}>Liste des coachs</span>
                  </Link>
                </li>
                <li>
                  <Link className={styles.link} to="/back/liste-collaborateurs" onClick={handleClose}>
                    <img src={clients} alt="" />
                    <span className={`${styles.text} ${styles.wrapped}`}>Liste des collaborateurs</span>
                  </Link>
                </li>
                <li>
                  <Link className={styles.link} to="/back/liste-tarifs" onClick={handleClose}>
                    <img src={money} alt="" />
                    <span className={`${styles.text} ${styles.wrapped}`}>Liste des tarifs</span>
                  </Link>
                </li>
                <li>
                  <a className={styles.link} href="https://dashboard.stripe.com/dashboard" target="_blank" rel="noreferrer">
                    <img src={stripeIcon} alt="" />
                    <span className={`${styles.text} ${styles.wrapped}`}>Dashboard Stripe</span>
                  </a>
                </li>
              </>
            )}
            <li>
              <Link className={styles.link} to="/">
                <img src={homeIcon} alt="" />
                <span className={`${styles.text} ${styles.wrapped}`}>Accueil du site</span>
              </Link>
            </li>
          </ul>
        </nav>
        <main className={styles.main}>
          {children}
        </main>
      </div>
    );
  }

  return null;
};

export default BackLayout;
