import { useEffect, useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import styles from '../styles/pages/Membre.module.scss';
import chevron from '../icons/chevron-right.svg';

const Concept = () => {
  const params = useParams();

  const [collaborateur, setCollaborateur] = useState({});

  const navigate = useNavigate();

  const fetchData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_REQUEST_URL}/collaborateurs/${params.id}`,
      {
        method: 'GET',
      },
    );
    if (response.ok) {
      const data = await response.json();
      setCollaborateur(data);
    } else {
      navigate('/collaborateurs');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>One2One | L'un de nos collaborateurs à votre service</title>
        <meta name="description" content="Découvrez nos collaborateurs. Ils sont prêts à vous accueillir." />
      </Helmet>
      <img className={styles.image} src={`${process.env.REACT_APP_IMAGE_URL}/${collaborateur.photo}`} alt="" />
      <section className={styles.content}>
        <Link className={styles.retour} to="/collaborateurs"><img src={chevron} alt="" />Retour</Link>
        <h1 className={styles.title}>{collaborateur?.fname} {collaborateur?.lname}</h1>
        <h3 className={styles.subtitle}>{collaborateur?.metier}</h3>
        <p className={styles.text}>{collaborateur?.description}</p>
        { collaborateur.phone && <p className={styles.text}>Téléphone : {collaborateur?.phone}</p> }
        { collaborateur.email && <p className={styles.text}>Email : {collaborateur?.email}</p> }
        { collaborateur.website && <p className={styles.text}><a className={styles.link} href={collaborateur?.website} target="_blank" rel="noreferrer">Site internet</a></p> }
      </section>
    </>
  );
};

export default Concept;
