import { useEffect, useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import useProtected from '../../lib/hooks/useProtected';
import useAuth from '../../lib/hooks/useAuth';
import styles from '../../styles/pages/Form-coachs-back.module.scss';
import arrow from '../../icons/arrow-return.svg';

const ModificationsCoachBack = () => {
  useProtected();
  const params = useParams();
  const [coach, setCoach] = useState({
    lname: '',
    fname: '',
    birthDate: '',
    phone: '',
    address: '',
    email: '',
    description: '',
    studios: [],
  });
  const [studiosList, setStudiosList] = useState([]);
  const [selectedFile, setFile] = useState(null);
  const { user } = useAuth();

  const navigate = useNavigate();

  const formatDate = (date) => {
    const d = new Date(date);
    let month = d.getMonth() + 1;
    let day = d.getDate();
    const year = d.getFullYear();

    if (month < 10) {
      month = `0${month}`;
    }
    if (day < 10) {
      day = `0${day}`;
    }

    return [year, month, day].join('-');
  };

  const fileSelectedHandler = (event) => {
    setFile(event.target.files[0]);
  };

  const fetchStudios = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_REQUEST_URL}/studios`,
      {
        method: 'GET',
      },
    );
    if (response.ok) {
      const data = await response.json();
      setStudiosList(data['hydra:member']);
    }
  };

  const fetchData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_REQUEST_URL}/coaches/${params.id}`,
      {
        method: 'GET',
      },
    );
    if (response.ok) {
      const data = await response.json();
      data.birthDate = formatDate(data.birthDate);

      setCoach(data);
    } else {
      navigate('/back/liste-coachs');
    }
  };

  const handleChange = (event) => {
    setCoach((state) => ({ ...state, [event.target.name]: event.target.value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let picture = coach.photo;

    if (selectedFile !== null) {
      picture = selectedFile.name;
    }

    const studios = studiosList.reduce((previous, current) => {
      if (event.target[current.name].checked) {
        return [...previous, current['@id']];
      }
      return previous;
    }, []);

    const body = {
      id: coach.id,
      email: coach.email,
      lname: coach.lname,
      fname: coach.fname,
      address: coach.address,
      birthDate: coach.birthDate,
      phone: coach.phone,
      photo: picture,
      description: coach.description,
      studios,
    };

    const response = await fetch(`${process.env.REACT_APP_REQUEST_URL}/coaches/${coach.id}`, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`,
      },
    });
    if (response.ok) {
      if (selectedFile !== null) {
        const reader = new FileReader();
        reader.readAsDataURL(selectedFile);

        reader.onload = async (e) => {
          const myBody = JSON.stringify({
            file: e.target.result,
            name: selectedFile.name,
          });

          const responseUpload = await fetch(
            `${process.env.REACT_APP_UPLOAD_URL}`,
            {
              method: 'POST',
              body: myBody,
              headers: {
                'Content-Type': 'application/json',
              },
            },
          );
          if (responseUpload.ok) {
            navigate('/back/liste-coachs');
          }
        };
      }
      navigate('/back/liste-coachs');
    }
  };

  useEffect(() => {
    fetchStudios();
    fetchData();
  }, []);

  return (
    <>
      <Link to="/back/liste-coachs" className={styles.lien}>
        <img src={arrow} alt="arrow" className={styles.image} />
        <p>Retourner à la liste des coachs</p>
      </Link>
      <h1 className={styles.titre}>Modification d&apos;un coach</h1>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.nomComplet}>
          <label className={styles.label} htmlFor="lname">
            <p>Nom</p>
            <input className={styles.champ} type="text" name="lname" value={coach?.lname} onChange={handleChange} required />
          </label>
          <label className={styles.label} htmlFor="fname">
            <p>Prénom</p>
            <input className={styles.champ} type="text" name="fname" value={coach?.fname} onChange={handleChange} required />
          </label>
        </div>
        <div className={styles.naissanceTelephone}>
          <label className={styles.label} htmlFor="birthDate">
            <p>Date de naissance</p>
            <input className={styles.champ} type="date" name="birthDate" value={coach?.birthDate} onChange={handleChange} required />
          </label>
          <label className={styles.label} htmlFor="phone">
            <p>Numéro de téléphone</p>
            <input className={styles.champ} type="text" name="phone" value={coach?.phone} onChange={handleChange} required />
          </label>
        </div>
        <div className={styles.adresse}>
          <label className={styles.label} htmlFor="address">
            <p>Adresse</p>
            <input className={styles.champ} type="text" name="address" value={coach?.address} onChange={handleChange} required />
          </label>
        </div>
        <div className={styles.email}>
          <label className={styles.label} htmlFor="email">
            <p>Email</p>
            <input className={styles.champ} type="email" name="email" value={coach?.email} onChange={handleChange} required />
          </label>
        </div>
        <div className={styles.photo}>
          <label className={styles.label} htmlFor="photo">
            <p>Photo</p>
            <div className={styles.fileInput}>
              {selectedFile ? selectedFile.name : 'Choisissez un fichier.'}
              <input type="file" name="photo" id="photo" onChange={fileSelectedHandler} />
            </div>
          </label>
          <label className={styles.label} htmlFor="description">
            <p>Description</p>
            <textarea className={styles.champ} name="description" value={coach?.description} onChange={handleChange} required />
          </label>
        </div>
        <p>Studios associés</p>
        <ul className={styles.studios}>
          {studiosList.map((studio) => (
            <li key={studio.id}>
              <label className={styles.label} htmlFor={studio.name}>
                <input
                  type="checkbox"
                  name={studio.name}
                  id={studio.name}
                  defaultChecked={coach?.studios.reduce((previous, current) => previous || current.id === studio.id, false)}
                />
                <p>{studio.name}</p>
              </label>
            </li>
          ))}
        </ul>
        <input className={styles.button} type="submit" />
      </form>
    </>
  );
};
export default ModificationsCoachBack;
