const parseDateWithSlashes = (date) => {
  if (!date) {
    return {};
  }
  const newDate = new Date(date);
  let month = newDate.getMonth() + 1;
  let day = newDate.getDate();
  const year = newDate.getFullYear();

  if (month < 10) {
    month = `0${month}`;
  }
  if (day < 10) {
    day = `0${day}`;
  }

  return `${day}/${month}/${year}`;
};

export default parseDateWithSlashes;
