import { Link } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Popup from 'react-customizable-popup';
import useAuth from '../../lib/hooks/useAuth';
import useProtected from '../../lib/hooks/useProtected';
import edit from '../../icons/edit.svg';
import plus from '../../icons/plus.svg';
import cross from '../../icons/cross.svg';
import styles from '../../styles/pages/Liste-back.module.scss';

const ListeCollaborateurs = () => {
  useProtected();
  const allCollaborateurs = useRef([]);
  const [collaborateurs, setCollaborateurs] = useState([]);
  const [currentCollaborateur, setCurrentCollaborateur] = useState({});
  const popupRef = useRef(null);
  const { user } = useAuth();

  const fetchData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_REQUEST_URL}/collaborateurs`,
      {
        method: 'GET',
      },
    );
    if (response.ok) {
      const data = await response.json();
      allCollaborateurs.current = data['hydra:member'];
      setCollaborateurs(data['hydra:member']);
    }
  };

  const handleClickCollaborateur = (collaborateur) => {
    popupRef.current.open();
    setCurrentCollaborateur({
      id: collaborateur.id,
      lname: collaborateur.lname,
      fname: collaborateur.fname,
      metier: collaborateur.metier,
      photo: collaborateur.photo,
      description: collaborateur.description,
      phone: collaborateur.phone,
      email: collaborateur.email,
      website: collaborateur.website,
    });
  };

  const handleDelete = async () => {
    const yes = confirm('Êtes-vous sûr de vouloir supprimer ce collaborateur ?');
    if (yes) {
      const response = await fetch(`${process.env.REACT_APP_REQUEST_URL}/collaborateurs/${currentCollaborateur.id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${user.token}`,
        },
      });
      if (response.ok) {
        fetchData();
        popupRef.current.close();
        alert('Ce collaborateur a bien été supprimé.');
      } else {
        alert('Une erreur est survenue.');
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>One2One | Liste des collaborateurs</title>
        <meta name="description" content="Ici sont listés tous les collaborateurs de la salle de sport One2One, le super coach peut modifier les données de collaborateurs en cliquant sur l’icône éditer." />
      </Helmet>
      <header className={styles.header}>
        <div>
          <h1 className={styles.titre}>Liste des collaborateurs</h1>
        </div>
      </header>
      <table className={styles.tableau}>
        <thead className={styles.tableau__header}>
          <tr>
            <th className={styles.tableau__entete}> </th>
            <th className={styles.tableau__entete}>Nom</th>
            <th className={styles.tableau__entete}>Prénom</th>
            <th className={`${styles.tableau__entete} ${styles.tableau__mobile}`}>Métier</th>
            <th className={`${styles.tableau__entete} ${styles.tableau__mobile}`}>Téléphone</th>
            <th className={`${styles.tableau__entete} ${styles.tableau__mobile}`}>Email</th>
            <th className={`${styles.tableau__entete} ${styles.tableau__mobile}`}>Site web</th>
            <th className={`${styles.tableau__entete} ${styles.tableau__desktop}`}> </th>
          </tr>
        </thead>
        <tbody className={styles.tableau__contenant}>
          { collaborateurs.map((collaborateur) => (
            <tr key={collaborateur.id} onClick={() => handleClickCollaborateur(collaborateur)}>
              <td className={styles.tableau__contenu}><Link to={`/back/modification-collaborateur/${collaborateur.id}`}><img src={edit} alt="editer" className={styles.icone} /></Link></td>
              <td className={styles.tableau__contenu}>{collaborateur.lname}</td>
              <td className={styles.tableau__contenu}>{collaborateur.fname}</td>
              <td className={`${styles.tableau__contenu} ${styles.tableau__mobile}`}>{collaborateur.metier}</td>
              <td className={`${styles.tableau__contenu} ${styles.tableau__mobile}`}>{collaborateur.phone}</td>
              <td className={`${styles.tableau__contenu} ${styles.tableau__mobile}`}>{collaborateur.email}</td>
              <td className={`${styles.tableau__contenu} ${styles.tableau__mobile}`}>{collaborateur.website}</td>
              <td className={`${styles.tableau__contenu} ${styles.tableau__desktop}`}><img src={plus} alt="editer" className={styles.icone} data-open /></td>
            </tr>
          ))}
        </tbody>
      </table>
      <aside className={styles.aside}>
        <Link to="/back/ajout-collaborateur" className={styles.aside__button}>Ajouter un collaborateur</Link>
      </aside>
      <Popup className={styles.popup} ref={popupRef} position="modal">
        <button className={styles.cross} type="button" data-close>
          <img src={cross} alt="" />
        </button>
        <h3 className={styles.title}>{currentCollaborateur.fname} {currentCollaborateur.lname}</h3>
        <p className={styles.text}>Métier<span className={styles.bold}>{currentCollaborateur.metier}</span></p>
        <p className={styles.text}>Téléphone<span className={styles.bold}>{currentCollaborateur.phone}</span></p>
        <p className={styles.text}>Email<span className={styles.bold}>{currentCollaborateur.email}</span></p>
        <p className={styles.text}>Site web<span className={styles.bold}>{currentCollaborateur.website}</span></p>
        <Link to={`/back/modification-collaborateur/${currentCollaborateur.id}`} className={styles.button}>Modifier les informations</Link>
        <button className={styles.deleteButton} type="button" onClick={handleDelete}>Supprimer le collaborateur</button>
      </Popup>
    </>
  );
};

export default ListeCollaborateurs;
