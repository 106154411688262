import { Link } from 'react-router-dom';
import styles from './Footer.module.scss';
import logo from '../../../icons/logo-footer.svg';
import facebook from '../../../icons/facebook.svg';
import instagram from '../../../icons/instagram.svg';
import youtube from '../../../icons/youtube.svg';

const Footer = () => (
  <footer className={styles.footer}>
    <figure className={styles.figure}>
      <img className={styles.logo} src={logo} alt="" />
      <figcaption className={styles.figcaption}>
        <h2 className={styles.figcaptionTitle}><strong>One2One</strong></h2>
        <p>Votre <strong>club sport santé bien-être à la Rochelle</strong>.</p>
      </figcaption>
    </figure>
    <div className={styles.listFooter}>
      <ul className={styles.linkContainer}>
        <li className={styles.linkTitle}>Explorer</li>
        <li className={styles.link}>
          <Link to="/">Accueil</Link>
        </li>
        <li className={styles.link}>
          <Link to="/concept">Le concept</Link>
        </li>
        <li className={styles.link}>
          <Link to="/activites">Les activités</Link>
        </li>
        <li className={styles.link}>
          <Link to="/coachs">Vos coachs</Link>
        </li>
        <li className={styles.link}>
          <Link to="/collaborateurs">Collaborateurs</Link>
        </li>
        <li className={styles.link}>
          <Link to="/contact">Nous contacter</Link>
        </li>
        <li className={styles.link}>
          <Link to="/mentions-legales">Mentions légales</Link>
        </li>
        <li className={styles.link}>
          <Link to="/cgv">Conditions générales de vente</Link>
        </li>
      </ul>
      <ul className={styles.linkContainer}>
        <li className={styles.linkTitle}>Où nous trouver</li>
        <li className={styles.text}>Rue des 3 Frères,</li>
        <li className={styles.text}>17000 La Rochelle</li>
      </ul>
      <ul className={styles.linkContainer}>
        <li className={styles.linkTitle}>Contact</li>
        <li className={styles.link}>
          <a href="mailto:one2one@gmail.com">one2one@gmail.com</a>
        </li>
        <li className={styles.link}>
          <a href="tel:0785626925">07 85 62 69 25</a>
        </li>
      </ul>
      <ul className={styles.networkContainer}>
        <li>
          <a href="https://www.instagram.com/one2onetrainingcenter/" target="_blank" rel="noreferrer">
            <img src={instagram} alt="instagram" className={styles.network} />
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/channel/UCkzulMjYOnptQ23e-6TmEWA" target="_blank" rel="noreferrer">
            <img src={youtube} alt="youtube" className={styles.network} />
          </a>
        </li>
        <li>
          <a href="https://www.facebook.com/one2one.larochelle?fref=ts" target="_blank" rel="noreferrer">
            <img src={facebook} alt="facebook" className={styles.network} />
          </a>
        </li>
      </ul>
    </div>
  </footer>
);

export default Footer;
