import { useEffect, useState } from 'react';

const useDB = (request) => {
  const [data, setData] = useState();

  useEffect(() => {
    (async () => {
      const response = await fetch(`${process.env.REACT_APP_REQUEST_URL}/${request}`);
      if (response.ok) {
        const newData = await response.json();
        setData(newData['hydra:member'] || newData);
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return data;
};

export default useDB;
