import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import styles from '../styles/pages/Membres.module.scss';

const Concept = () => {
  const [coachs, setCoach] = useState([]);

  const tabCoachs = [];

  const fetchData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_REQUEST_URL}/coaches`,
      {
        method: 'GET',
      },
    );
    if (response.ok) {
      const data = await response.json();
      data['hydra:member'].forEach((coach) => {
        tabCoachs.push(coach);
      });
      setCoach(tabCoachs);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>One2One | Nos coachs sont à votre service</title>
        <meta name="description" content="Les coachs One2One spécialisés en bien-être, biking, ou cross-training sont prêts à vous accueillir et vous proposent des entraînements personnalisés !" />
      </Helmet>
      <header className={styles.header}>
        <div>
          <h1 className={styles.title}><strong>Coachs</strong></h1>
          <h2 className={styles.subtitle}>Une équipe <span className={styles.pink}>à votre service</span> pour vous accompagner</h2>
        </div>
      </header>
      { coachs.map((coach) => (
        <section key={coach.id} className={styles.image} style={{ backgroundImage: `url(${process.env.REACT_APP_IMAGE_URL}/${coach.photo})` }}>
          <h3 className={styles.name}>{coach.fname}</h3>
          <Link className={styles.button} to={`/coach/${coach.id}`}>Voir le profil</Link>
        </section>
      ))}

    </>
  );
};

export default Concept;
