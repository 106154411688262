// import { Link } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import styles from '../styles/pages/Concept.module.scss';
import concept1 from '../images/concept1.jpg';
import concept2 from '../images/concept2.jpg';
import concept3 from '../images/concept3.jpg';

const Concept = () => (
  <>
    <Helmet>
      <title>One2One | Un concept original pour une personnalisation complète</title>
      <meta name="description" content="Votre salle de sport rochelaise One2One s’engage à réaliser des séances personnalisées en petit comité avec des coachs motivés pour répondre au mieux à vos besoins." />
    </Helmet>
    <header className={styles.header}>
      <div>
        <h1 className={styles.title}><strong>Concept</strong></h1>
        <h2 className={styles.subtitle}>Personnalisez vos <strong>sessions sportives</strong>.</h2>
      </div>
      <img src={concept1} alt="concept1" className={styles.image} />
    </header>
    <section className={styles.section1}>
      <img src={concept2} alt="concept2" className={styles.image} />
      <article className={styles.texts}>
        <p className={styles.text}>Chacune des activités proposées par ONE2ONE apporte des <span className={styles.pink}>bénéfices physiques complémentaires</span>. Nous sommes là pour vous conseiller et <span className={styles.pink}>vous guider sur le meilleur « mix »</span> sportif au service de votre entrainement le plus efficace.</p>
        <p className={styles.text}>À chaque activité son studio, à chaque studio son ambiance qui vous placera en immersion pour une <strong>session de sport</strong> aussi intense qu’exaltante.</p>
        <Link to="/coachs" className={styles.button}>Découvrir les coachs</Link>
      </article>
    </section>
    <section className={styles.section2}>
      <article className={styles.article}>
        <h2 className={styles.title}>Une approche radicalement différente</h2>
        <h3 className={styles.subtitle}>Vivez une expérience 100% connectée.</h3>
        <p className={styles.text}>Passer au <strong>fitness connecté</strong> 2.0 grâce au système <strong>SPiVi</strong> qui permet un suivi individualisé de vos <strong>sessions de sport</strong>.</p>
        <p className={styles.text}>L’ensemble des données collectées (fréquences cardiaques, calories, zone de travail par palier de couleur, …) est transmise pendant votre séance en direct sur grand écran afin de suivre votre performance ainsi que celle du groupe afin de vous permettre de vous challenger, de trouver une source d’inspiration et de motivation pour vous dépasser et vous dépenser.</p>
        <Link to="/activites" className={styles.button}>Découvrir les activités</Link>
      </article>
      <img src={concept3} alt="concept3" className={styles.image} />
    </section>
  </>
);

export default Concept;
