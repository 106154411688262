import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import bcrypt from 'bcryptjs';
import styles from '../styles/pages/Inscription.module.scss';

const Inscription = () => {
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const salt = bcrypt.genSaltSync(10);

    const {
      fname,
      lname,
      birthDate,
      phone,
      email,
      address,
    } = event.target;

    const generatedCard = Math.floor(10000000 + Math.random() * 90000000).toString(10);
    const hashedPassword = bcrypt.hashSync(generatedCard, salt);
    const bodyClient = JSON.stringify({
      lname: lname.value,
      fname: fname.value,
      birthDate: birthDate.value,
      phone: phone.value,
      email: email.value,
      address: address.value,
      password: hashedPassword,
      seances: [],
      credits: 1,
      isVerified: true,
      card: generatedCard,
      roles: ['ROLE_USER'],
    });
    const response = await fetch(`${process.env.REACT_APP_REQUEST_URL}/clients`, {
      method: 'POST',
      body: bodyClient,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.ok) {
      const bodyMailAccount = JSON.stringify({
        fname: fname.value,
        email: email.value,
        card: generatedCard,
      });
      const responseMailAccount = await fetch(
        `${process.env.REACT_APP_MAIL_URL}/inscription`,
        {
          method: 'POST',
          body: bodyMailAccount,
        },
      );
      if (responseMailAccount.ok) {
        alert('Vous avez reçu un mail contenant le numéro de carte vous permettant de vous connecter.');
      } else {
        alert('Une erreur s\'est produite lors de l\'envoi du mail, veuillez contacter un administrateur pour recevoir votre numéro de carte.');
      }
      navigate('/');
    } else {
      alert('Une erreur s\'est produite.');
    }
  };

  return (
    <>
      <Helmet>
        <title>One2One | Inscrivez-vous pour accéder à toutes les fonctionnalités</title>
        <meta name="description" content="Inscrivez-vous à la salle de sport rochelaise One2One pour accéder à votre espace client, recharger les crédits de votre carte et réserver vos séances en ligne." />
      </Helmet>
      <div className={styles.wrapper}>
        <h1 className={styles.title}>Inscription</h1>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.horizontal}>
            <label className={styles.label} htmlFor="lname">
              Nom
              <input
                className={styles.input}
                name="lname"
                id="lname"
                type="text"
                placeholder="Votre nom"
                required
              />
            </label>
            <label className={styles.label} htmlFor="fname">
              Prénom
              <input
                className={styles.input}
                name="fname"
                id="fname"
                type="text"
                placeholder="Votre prénom"
                required
              />
            </label>
          </div>
          <label className={styles.label} htmlFor="birthDate">
            Date de naissance
            <input
              className={styles.input}
              name="birthDate"
              id="birthDate"
              type="date"
              required
            />
          </label>
          <label className={styles.label} htmlFor="phone">
            Numéro de téléphone
            <input
              className={styles.input}
              name="phone"
              id="phone"
              type="tel"
              placeholder="01234567"
              required
            />
          </label>
          <label className={styles.label} htmlFor="email">
            Adresse mail
            <input
              className={styles.input}
              name="email"
              id="email"
              type="email"
              placeholder="exemple@gmail.com"
              required
            />
          </label>
          <label className={styles.label} htmlFor="address">
            Adresse
            <input
              className={styles.input}
              name="address"
              id="address"
              type="text"
              placeholder="35 Avenue du général..."
              required
            />
          </label>
          <label className={styles.checkLabel} htmlFor="confirm">
            <input
              className={styles.checkbox}
              name="confirm"
              id="confirm"
              type="checkbox"
              required
            />
            <p>Je confirme avoir pris connaissance des <Link className={styles.bold} to="/politique-confidentialite" target="_blank" rel="noopener noreferrer">conditions d'utilisation</Link></p>
          </label>
          <input className={styles.button} type="submit" value="S'inscrire" />
        </form>
      </div>
    </>
  );
};

export default Inscription;
