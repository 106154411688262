import { useEffect, useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import styles from '../styles/pages/Membre.module.scss';
import chevron from '../icons/chevron-right.svg';

const Concept = () => {
  const params = useParams();

  const [coach, setCoach] = useState({});

  const navigate = useNavigate();

  const fetchData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_REQUEST_URL}/coaches/${params.id}`,
      {
        method: 'GET',
      },
    );
    if (response.ok) {
      const data = await response.json();
      setCoach(data);
    } else {
      navigate('/coachs');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>One2One | L'un de nos coachs à votre service</title>
        <meta name="description" content="Découvrez nos coachs spécialistes en bien-être, biking, ou cross-training. Ils sont prêts à vous accueillir, tout en vous proposent des entraînements adaptés à vos besoins !" />
      </Helmet>
      <img className={styles.image} src={`${process.env.REACT_APP_IMAGE_URL}/${coach.photo}`} alt="" />
      <section className={styles.content}>
        <Link className={styles.retour} to="/coachs"><img src={chevron} alt="" />Retour</Link>
        <h1 className={styles.title}>{coach?.fname}</h1>
        <p className={styles.text}>{coach?.description}</p>
        <ul className={styles.list}>
          { coach?.studios?.map((studio) => (
            <li className={`${studio.name} ${styles.item}`}>{studio.name}</li>
          ))}
        </ul>
      </section>
    </>
  );
};

export default Concept;
