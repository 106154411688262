import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import useAuth from '../lib/hooks/useAuth';
import styles from '../styles/pages/Connexion.module.scss';

const Connexion = () => {
  const { login } = useAuth();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { email, password } = event.target;
    login(email.value, password.value);
  };

  return (
    <>
      <Helmet>
        <title>One2One | Connectez-vous pour accéder à toutes les fonctionnalités</title>
        <meta name="description" content="La connexion au site One2One vous permet de réserver les séances de votre choix, payer directement en ligne et voir l'historique de toutes vos séances." />
      </Helmet>
      <div className={styles.wrapper}>
        <h1 className={styles.title}>Connexion</h1>
        <form className={styles.form} onSubmit={handleSubmit}>
          <label className={styles.label} htmlFor="email">
            Adresse mail
            <input
              className={styles.input}
              name="email"
              id="email"
              type="email"
              placeholder="exemple@gmail.com"
              required
            />
          </label>
          <label className={styles.label} htmlFor="password">
            Numéro de carte ou mot de passe
            <input
              className={styles.input}
              name="password"
              id="password"
              type="password"
              placeholder="Votre numéro de carte ou mot de passe"
              required
            />
          </label>
          <span className={styles.checkLabel}>
            <p>Vous n'avez pas de compte ? <Link className={styles.bold} to="/inscription" rel="noopener noreferrer">Inscrivez-vous !</Link></p>
          </span>
          <input className={styles.button} type="submit" value="Se connecter" />
        </form>
      </div>
    </>
  );
};

export default Connexion;
