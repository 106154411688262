import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import arrow from '../../icons/arrow-return.svg';
import useProtected from '../../lib/hooks/useProtected';
import useAuth from '../../lib/hooks/useAuth';
import styles from '../../styles/pages/Form-tarifs-back.module.scss';

const AjoutTarifBack = () => {
  useProtected();
  const [tarif, setTarif] = useState({});
  const { user } = useAuth();

  const navigate = useNavigate();

  const handleChange = (event) => {
    setTarif((state) => ({ ...state, [event.target.name]: event.target.value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const body = JSON.stringify({
      price: parseInt(tarif.price, 10),
      sessions: parseInt(tarif.sessions, 10),
      priceId: tarif.priceId,
    });

    const response = await fetch(`${process.env.REACT_APP_REQUEST_URL}/tarifs`, {
      method: 'POST',
      body,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`,
      },
    });
    if (response.ok) {
      navigate('/back/liste-tarifs');
    }
  };

  return (
    <>
      <Link to="/back/liste-tarifs" className={styles.lien}>
        <img src={arrow} alt="arrow" className={styles.image} />
        <p>Retourner à la liste des tarifs</p>
      </Link>
      <h1 className={styles.titre}>Ajout d&apos;un tarif</h1>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.tarif}>
          <label className={styles.label} htmlFor="price">
            <p>Prix</p>
            <input className={styles.champ} type="number" name="price" onChange={handleChange} min="1" required />
          </label>
          <label className={styles.label} htmlFor="sessions">
            <p>Nombre de séances</p>
            <input className={styles.champ} type="number" name="sessions" onChange={handleChange} min="1" required />
          </label>
          <label className={styles.label} htmlFor="priceId">
            <p>Id Stripe</p>
            <input className={styles.champ} type="text" name="priceId" onChange={handleChange} required />
          </label>
        </div>
        <input className={styles.button} type="submit" />
      </form>
    </>
  );
};
export default AjoutTarifBack;
