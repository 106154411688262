import { Helmet } from 'react-helmet-async';
import styles from '../styles/pages/Contact.module.scss';

const Contact = () => (
  <>
    <Helmet>
      <title>One2One | Retrouver nos coordonnées</title>
      <meta name="description" content="Retrouver notre localisation, nos coordonnées afin de nous contacter et d'accéder à la salle de sport One2One le plus facilement." />
    </Helmet>
    <div className={styles.wrapper}>
      <figure className={styles.figure}>
        <figcaption className={styles.figcaption}>
          <h1 className={styles.hero}>Restez connecté avec <span className={styles.pink}>notre équipe</span></h1>
          <article className={styles.article}>
            <h2 className={styles.title}>Nous retrouver</h2>
            <p className={styles.paragraph}>RUE DES 3 FRERES</p>
            <p className={styles.paragraph}>17000 LA ROCHELLE</p>
          </article>
          <article className={styles.article}>
            <h2 className={styles.title}>Nous contacter</h2>
            <p className={styles.paragraph}><a className={styles.link} href="tel:0785626925">07 85 62 69 25</a></p>
            <p className={styles.paragraph}><a className={styles.link} href="mail:one2one.larochelle@gmail.com">one2one.larochelle@gmail.com</a></p>
          </article>
        </figcaption>
        <iframe className={styles.map} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d839.596971465992!2d-1.1958409700935153!3d46.159856399747625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4801529162be6ed3%3A0x4a1e493f48c56223!2sONE%202%20ONE%20SPORT%20SANTE%20BIEN-ETRE!5e0!3m2!1sfr!2sfr!4v1654417922203!5m2!1sfr!2sfr" allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="map" />
      </figure>
    </div>
  </>
);

export default Contact;
