/* eslint-disable camelcase */
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import AuthContext from '../contexts/AuthContext';

const useAuth = () => {
  const { user, setUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const getToken = async (username, password, callback) => {
    const response = await fetch(`${process.env.REACT_APP_REQUEST_URL}/login_check`, {
      method: 'POST',
      body: JSON.stringify({
        username,
        password,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.ok) {
      const { token, refresh_token } = await response.json();
      callback(token, refresh_token);
    } else {
      alert('Mauvais identifiant ou mot de passe.');
    }
  };

  const refreshToken = async (t, callback) => {
    const response = await fetch(`${process.env.REACT_APP_REQUEST_URL}/token/refresh`, {
      method: 'POST',
      body: JSON.stringify({
        refresh_token: t,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.ok) {
      const { token, refresh_token } = await response.json();
      callback(token, refresh_token);
    }
  };

  const getUser = async (username, token, refresh_token, callback) => {
    const clientAccount = fetch(`${process.env.REACT_APP_REQUEST_URL}/clients?email=${username}`);
    const coachAccount = fetch(`${process.env.REACT_APP_REQUEST_URL}/coaches?email=${username}`);
    const [clientRes, coachRes] = await Promise.all([clientAccount, coachAccount]);
    if (clientRes.ok && coachRes.ok) {
      const client = await clientRes.json();
      const coach = await coachRes.json();
      const newUser = client['hydra:member'][0] || coach['hydra:member'][0];
      newUser.seances = [];
      setUser({ ...newUser, token, refresh_token });
      Cookies.set('user', JSON.stringify({ ...newUser, token, refresh_token }), { path: '/', expires: 30 });
      if (callback) {
        callback(newUser);
      }
    } else {
      alert('Une erreur s\'est produite.');
    }
  };
  const updateUser = (newInfos) => {
    setUser((state) => {
      const newState = { ...state, ...newInfos };
      Cookies.set('user', JSON.stringify(newState), { path: '/', expires: 30 });
      return newState;
    });
  };

  const login = async (username, password, query) => {
    getToken(username, password, (token, refresh_token) => {
      getUser(username, token, refresh_token, (newUser) => {
        navigate(newUser['@type'] === 'Coach' ? '/back/planning' : `/${query || ''}`);
      });
    });
  };

  const logout = () => {
    setUser(null);
    Cookies.remove('user');
    navigate('/');
  };

  return {
    user,
    getUser,
    updateUser,
    refreshToken,
    login,
    logout,
  };
};

export default useAuth;
