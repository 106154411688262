import { useEffect, useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import useAuth from '../../lib/hooks/useAuth';
import useProtected from '../../lib/hooks/useProtected';
import styles from '../../styles/pages/Form-collaborateurs-back.module.scss';
import arrow from '../../icons/arrow-return.svg';

const ModificationsCollaborateurBack = () => {
  useProtected();
  const params = useParams();
  const [collaborateur, setCollaborateur] = useState({
    lname: '',
    fname: '',
    metier: '',
    photo: '',
    description: '',
    phone: '',
    email: '',
    website: '',
  });
  const [selectedFile, setFile] = useState();
  const { user } = useAuth();

  const navigate = useNavigate();

  const fileSelectedHandler = (event) => {
    setFile(event.target.files[0]);
  };

  const fetchData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_REQUEST_URL}/collaborateurs/${params.id}`,
      {
        method: 'GET',
      },
    );
    if (response.ok) {
      const data = await response.json();
      setCollaborateur(data);
    } else {
      navigate('/back/liste-collaborateurs');
    }
  };

  const handleChange = (event) => {
    setCollaborateur((state) => ({ ...state, [event.target.name]: event.target.value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let picture = collaborateur.photo;

    if (typeof selectedFile === 'object') {
      picture = selectedFile.name;
    }

    const body = {
      id: collaborateur.id,
      lname: collaborateur.lname,
      fname: collaborateur.fname,
      metier: collaborateur.metier,
      photo: picture,
      description: collaborateur.description,
      phone: collaborateur.phone,
      email: collaborateur.email,
      website: collaborateur.website,
    };

    const response = await fetch(`${process.env.REACT_APP_REQUEST_URL}/collaborateurs/${collaborateur.id}`, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`,
      },
    });
    if (response.ok) {
      if (typeof selectedFile === 'object') {
        const reader = new FileReader();
        reader.readAsDataURL(selectedFile);

        reader.onload = async (e) => {
          const myBody = JSON.stringify({
            file: e.target.result,
            name: selectedFile.name,
          });

          const responseUpload = await fetch(
            `${process.env.REACT_APP_UPLOAD_URL}`,
            {
              method: 'POST',
              body: myBody,
              headers: {
                'Content-Type': 'application/json',
              },
            },
          );
          if (responseUpload.ok) {
            navigate('/back/liste-collaborateurs');
          }
        };
      }
      navigate('/back/liste-collaborateurs');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Link to="/back/liste-collaborateurs" className={styles.lien}>
        <img src={arrow} alt="arrow" className={styles.image} />
        <p>Retourner à la liste des collaborateurs</p>
      </Link>
      <h1 className={styles.titre}>Modification d'un collaborateur</h1>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.nomComplet}>
          <label className={styles.label} htmlFor="lname">
            <p>Nom</p>
            <input className={styles.champ} type="text" name="lname" value={collaborateur?.lname} onChange={handleChange} required />
          </label>
          <label className={styles.label} htmlFor="fname">
            <p>Prénom</p>
            <input className={styles.champ} type="text" name="fname" value={collaborateur?.fname} onChange={handleChange} required />
          </label>
        </div>
        <div className={styles.naissanceTelephone}>
          <label className={styles.label} htmlFor="metier">
            <p>Métier</p>
            <input className={styles.champ} type="text" name="metier" value={collaborateur?.metier} onChange={handleChange} required />
          </label>
          <label className={styles.label} htmlFor="phone">
            <p>Téléphone</p>
            <input className={styles.champ} type="text" name="phone" value={collaborateur?.phone} onChange={handleChange} />
          </label>
        </div>
        <div className={styles.naissanceTelephone}>
          <label className={styles.label} htmlFor="email">
            <p>Email</p>
            <input className={styles.champ} type="text" name="email" value={collaborateur?.email} onChange={handleChange} />
          </label>
          <label className={styles.label} htmlFor="website">
            <p>Site web</p>
            <input className={styles.champ} type="text" name="website" value={collaborateur?.website} onChange={handleChange} />
          </label>
        </div>
        <div className={styles.photo}>
          <label className={styles.label} htmlFor="photo">
            <p>Photo</p>
            <div className={styles.fileInput}>
              {selectedFile ? selectedFile.name : 'Choisissez un fichier.'}
              <input type="file" name="photo" id="photo" onChange={fileSelectedHandler} />
            </div>
          </label>
          <label className={styles.label} htmlFor="description">
            <p>Description</p>
            <textarea className={styles.champ} name="description" value={collaborateur?.description} onChange={handleChange} required />
          </label>
        </div>
        <input className={styles.button} type="submit" />
      </form>
    </>
  );
};
export default ModificationsCollaborateurBack;
