import styles from '../styles/pages/Mentions.module.scss';

const Mentions = () => (
  <>
    <h1 className={styles.titre}>Mentions légales</h1>
    <h2 className={styles.soustitre}>Présentation de l'entreprise</h2>
    <p>Identité de l'entreprise : ONE2ONE</p>
    <p>Directeur de la publication : M. Alexandre BARES</p>
    <p>Forme juridique : SAS </p>
    <p>SIRET : 79438883500017</p>
    <p>Adresse du siège social : ONE2ONE, RUE DES TROIS FRERES, 17000 LA ROCHELLE</p>
    <p>Capital social : 5 000,00 €</p>
    <p>Numéro RCS : La Rochelle B 794 388 835</p>
    <p>Adresse mail : one2one.larochelle@gmail.com</p>
    <p>Numéro de téléphone : 07 85 62 69 25</p>
    <p>Numéro de TVA : FR95794388835</p>
    <h2 className={styles.soustitre}>Présentation du site</h2>
    <p className={styles.texte}><strong>Créateur</strong> : <a href="https://agence-zarya.lpmiaw.univ-lr.fr/">Agence Zarya</a></p>
    <p className={styles.texte}>Développeurs : Bazile BINSON, Manon BOUCHERON, Gabriel GAUME, Colin LIENARD</p>
    <p className={`${styles.dernier} ${styles.texte}`}>Hébergeur : OVH, 2 rue Kellermann - 59100 Roubaix - France, Numéro du service client : 1007</p>
  </>
);

export default Mentions;
