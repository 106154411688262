import { Link } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import Popup from 'react-customizable-popup';
import useProtected from '../../lib/hooks/useProtected';
import edit from '../../icons/edit.svg';
import plus from '../../icons/plus.svg';
import cross from '../../icons/cross.svg';
import styles from '../../styles/pages/Liste-back.module.scss';

const ListeTarifs = () => {
  useProtected();
  const [tarifs, setTarifs] = useState([]);
  const [currentTarif, setCurrentTarif] = useState({});
  const popupRef = useRef(null);

  const fetchData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_REQUEST_URL}/tarifs`,
      {
        method: 'GET',
      },
    );
    if (response.ok) {
      const data = await response.json();
      setTarifs(data['hydra:member']);
    }
  };

  const handleClickTarif = (tarif) => {
    popupRef.current.open();
    setCurrentTarif({
      id: tarif.id,
      price: tarif.price,
      sessions: tarif.sessions,
      priceId: tarif.priceId,
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <header className={styles.header}>
        <div>
          <h1 className={styles.titre}>Liste des tarifs</h1>
        </div>
      </header>
      <table className={styles.tableau}>
        <thead className={styles.tableau__header}>
          <tr>
            <th className={styles.tableau__entete}> </th>
            <th className={styles.tableau__entete}>Nombre de séances</th>
            <th className={styles.tableau__entete}>Prix</th>
            <th className={`${styles.tableau__entete} ${styles.tableau__mobile}`}>Id Stripe</th>
            <th className={`${styles.tableau__entete} ${styles.tableau__desktop}`}> </th>
          </tr>
        </thead>
        <tbody className={styles.tableau__contenant}>
          { tarifs.map((tarif) => (
            <tr key={tarif.id} onClick={() => handleClickTarif(tarif)}>
              <td className={styles.tableau__contenu}><Link to={`/back/modification-tarif/${tarif.id}`}><img src={edit} alt="editer" className={styles.icone} /></Link></td>
              <td className={styles.tableau__contenu}>{tarif.sessions}</td>
              <td className={styles.tableau__contenu}>{tarif.price}€</td>
              <td className={`${styles.tableau__contenu} ${styles.tableau__mobile}`}>{tarif.priceId}</td>
              <td className={`${styles.tableau__contenu} ${styles.tableau__desktop}`}><img src={plus} alt="editer" className={styles.icone} data-open /></td>
            </tr>
          ))}
        </tbody>
      </table>
      <aside className={styles.aside}>
        <Link to="/back/ajout-tarif" className={styles.aside__button}>Ajouter un tarif</Link>
      </aside>
      <Popup className={styles.popup} ref={popupRef} position="modal">
        <button className={styles.cross} type="button" data-close>
          <img src={cross} alt="" />
        </button>
        <h3 className={styles.title}>Tarif n°{currentTarif.id}</h3>
        <p className={styles.text}>Prix<span className={styles.bold}>{currentTarif.price}€</span></p>
        <p className={styles.text}>Nombre de séances<span className={styles.bold}>{currentTarif.sessions}</span></p>
        <p className={styles.text}>Id Stripe<span className={styles.bold}>{currentTarif.priceId}</span></p>
        <Link to={`/back/modification-tarif/${currentTarif.id}`} className={styles.button}>Modifier les informations</Link>
      </Popup>
    </>
  );
};

export default ListeTarifs;
