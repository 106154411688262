import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import Line from '../Line/Line';
import useAuth from '../../../lib/hooks/useAuth';
import styles from './Layout.module.scss';

const Layout = ({ children, line }) => {
  const location = useLocation();
  const { user, getUser, refreshToken } = useAuth();

  useEffect(() => {
    if (user) {
      refreshToken(user.refresh_token, (token, refresh) => {
        getUser(user.email, token, refresh);
      });
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className={styles.wrapper}>
      <Navbar />
      {line && (
        <Line />
      )}
      <main className={styles.main}>
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
