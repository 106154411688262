/* eslint-disable react/jsx-filename-extension */
import React, { useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Cookies from 'js-cookie';
import Home from './App';
import Layout from './components/Common/Layout/Layout';
import Concept from './routes/concept';
import Mentions from './routes/mentions';
import CGV from './routes/cgv';
import PolitiqueConfidentialite from './routes/politique-confidentialite';
import Coachs from './routes/coachs';
import Coach from './routes/coach';
import Reservation from './routes/reservation';
import Contact from './routes/contact';
import BackLayout from './components/Back/BackLayout/BackLayout';
import Connexion from './routes/connexion';
import Inscription from './routes/inscription';
import PlanningBack from './routes/back/planning';
import Activites from './routes/activites';
import Activite from './routes/activite';
import Collaborateurs from './routes/collaborateurs';
import Collaborateur from './routes/collaborateur';
import Profil from './routes/profil';
import ListeClients from './routes/back/liste-clients';
import ModificationsClientBack from './routes/back/modification-client';
import AjoutClientBack from './routes/back/ajout-client';
import ListeCoachs from './routes/back/liste-coachs';
import ModificationsCoachBack from './routes/back/modification-coach';
import AjoutCoachBack from './routes/back/ajout-coach';
import ListeCollaborateurs from './routes/back/liste-collaborateurs';
import ModificationsCollaborateurBack from './routes/back/modification-collaborateur';
import AjoutCollaborateurBack from './routes/back/ajout-collaborateur';
import ListeTarifs from './routes/back/liste-tarifs';
import ModificationsTarifBack from './routes/back/modification-tarif';
import AjoutTarifBack from './routes/back/ajout-tarif';
import Historique from './routes/historique';
// import Recharger from './routes/recharger';
import NoPageFound from './routes/no-page-found';
import NomsSeances from './routes/back/noms-seances';
import AuthContext from './lib/contexts/AuthContext';
import './styles/_reset.scss';
import './styles/_global.scss';

const App = () => {
  const [user, setUser] = useState(Cookies.get('user') ? JSON.parse(Cookies.get('user')) : null);

  return (
    <AuthContext.Provider value={useMemo(() => ({ user, setUser }), [user])}>
      <HelmetProvider>
        <Helmet>
          <link rel="canonical" href="https://www.one2one-larochelle.com/" />
        </Helmet>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout line><Home /></Layout>} />
            <Route path="concept" element={<Layout line><Concept /></Layout>} />
            <Route path="contact" element={<Layout><Contact /></Layout>} />
            <Route path="coachs" element={<Layout><Coachs /></Layout>} />
            <Route path="coach/:id" element={<Layout><Coach /></Layout>} />
            <Route path="reservation" element={<Layout><Reservation /></Layout>} />
            <Route path="connexion" element={<Layout><Connexion /></Layout>} />
            <Route path="inscription" element={<Layout><Inscription /></Layout>} />
            <Route path="activites" element={<Layout><Activites /></Layout>} />
            <Route path="activite/:name" element={<Layout><Activite /></Layout>} />
            <Route path="profil" element={<Layout><Profil /></Layout>} />
            <Route path="mentions-legales" element={<Layout><Mentions /></Layout>} />
            <Route path="cgv" element={<Layout><CGV /></Layout>} />
            <Route path="politique-confidentialite" element={<Layout><PolitiqueConfidentialite /></Layout>} />
            <Route path="historique" element={<Layout><Historique /></Layout>} />
            {/* <Route path="recharger" element={<Layout><Recharger /></Layout>} /> */}
            <Route path="collaborateurs" element={<Layout><Collaborateurs /></Layout>} />
            <Route path="collaborateur/:id" element={<Layout><Collaborateur /></Layout>} />
            <Route path="back">
              <Route path="planning" element={<BackLayout><PlanningBack /></BackLayout>} />
              <Route path="noms-seances" element={<BackLayout><NomsSeances /></BackLayout>} />
              <Route path="liste-clients" element={<BackLayout><ListeClients /></BackLayout>} />
              <Route path="modification-client/:id" element={<BackLayout><ModificationsClientBack /></BackLayout>} />
              <Route path="ajout-client" element={<BackLayout><AjoutClientBack /></BackLayout>} />
              <Route path="liste-coachs" element={<BackLayout><ListeCoachs /></BackLayout>} />
              <Route path="modification-coach/:id" element={<BackLayout><ModificationsCoachBack /></BackLayout>} />
              <Route path="ajout-coach" element={<BackLayout><AjoutCoachBack /></BackLayout>} />
              <Route path="liste-collaborateurs" element={<BackLayout><ListeCollaborateurs /></BackLayout>} />
              <Route path="modification-collaborateur/:id" element={<BackLayout><ModificationsCollaborateurBack /></BackLayout>} />
              <Route path="ajout-collaborateur" element={<BackLayout><AjoutCollaborateurBack /></BackLayout>} />
              <Route path="liste-tarifs" element={<BackLayout><ListeTarifs /></BackLayout>} />
              <Route path="modification-tarif/:id" element={<BackLayout><ModificationsTarifBack /></BackLayout>} />
              <Route path="ajout-tarif" element={<BackLayout><AjoutTarifBack /></BackLayout>} />
            </Route>
            <Route path="*" element={<Layout><NoPageFound /></Layout>} />
          </Routes>
        </BrowserRouter>
      </HelmetProvider>
    </AuthContext.Provider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
