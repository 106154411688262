import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import useMobile from '../../../lib/hooks/useMobile';
import useAuth from '../../../lib/hooks/useAuth';
import logo from '../../../icons/logo.svg';
import menu from '../../../icons/menu.svg';
import cross from '../../../icons/cross.svg';
import arrow from '../../../icons/arrow.svg';
import styles from './Navbar.module.scss';
import ClientSpace from '../ClientSpace/ClientSpace';

const Navbar = () => {
  const isMobile = useMobile();
  const [menuOpen, setMenuOpen] = useState(false);
  const { user } = useAuth();
  const location = useLocation();

  return (
    <>
      <nav className={styles.navbar}>
        <div className={styles.wrapper}>
          {isMobile ? (
            <>
              <Link to="/">
                <img className={styles.logo} src={logo} alt="" />
              </Link>
              <button className={styles.menuIcon} onClick={() => setMenuOpen(true)} type="button">
                <img src={menu} alt="" />
              </button>
            </>
          ) : (
            <>
              <ul className={styles.linkContainer}>
                <li>
                  <Link to="/">
                    <img className={styles.logo} src={logo} alt="" />
                  </Link>
                </li>
                <li className={`${styles.link} ${location.pathname === '/concept' ? styles.selected : ''}`}>
                  <Link to="/concept">Le concept</Link>
                </li>
                <li className={`${styles.link} ${location.pathname === '/activites' ? styles.selected : ''}`}>
                  <Link to="/activites">Les activités</Link>
                </li>
                <li className={`${styles.link} ${location.pathname === '/coachs' ? styles.selected : ''}`}>
                  <Link to="/coachs">Vos coachs</Link>
                </li>
                <li className={`${styles.link} ${location.pathname === '/collaborateurs' ? styles.selected : ''}`}>
                  <Link to="/collaborateurs">Collaborateurs</Link>
                </li>
                <li className={`${styles.link} ${location.pathname === '/contact' ? styles.selected : ''}`}>
                  <Link to="/contact">Nous contacter</Link>
                </li>
              </ul>
              <button className={styles.button} onClick={() => setMenuOpen(true)} type="button">
                <img className={styles.arrow} src={arrow} alt="" />
                {user ? `${user.fname} ${user.lname}` : 'Espace client'}
              </button>
            </>
          )}
        </div>
      </nav>
      {!isMobile && (
        <div
          className={`${styles.backdrop} ${menuOpen && styles.active}`}
          onClick={() => setMenuOpen(false)}
          role="button"
          aria-hidden
        />
      )}
      <aside className={`${styles.menu} ${menuOpen && styles.open}`}>
        <header className={styles.header}>
          <button className={styles.close} onClick={() => setMenuOpen(false)} type="button">
            <img src={cross} alt="" />
          </button>
          {user ? (
            <ClientSpace closeMenu={() => setMenuOpen(false)} />
          ) : (
            <>
              <Link className={styles.signInButton} to="/connexion" onClick={() => setMenuOpen(false)}>Se connecter</Link>
              <p className={styles.text}>Vous n&apos;avez pas de compte ?</p>
              <Link className={styles.signUpButton} to="/inscription" onClick={() => setMenuOpen(false)}>S&apos;inscrire</Link>
            </>
          )}
        </header>
        <hr className={styles.separator} />
        <div className={styles.main}>
          <h2 className={styles.title}>
            <span className={styles.hero}>One2One</span>
            <span className={styles.subhero}>votre club de fitness sur mesure</span>
            <span className={styles.subhero}>à La Rochelle</span>
          </h2>
          {isMobile && (
            <ul className={styles.linkContainer}>
              <li className={styles.link}>
                <Link to="/" onClick={() => setMenuOpen(false)}>Accueil</Link>
              </li>
              <li className={styles.link}>
                <Link to="/concept" onClick={() => setMenuOpen(false)}>Le concept</Link>
              </li>
              <li className={styles.link}>
                <Link to="/activites" onClick={() => setMenuOpen(false)}>Les activités</Link>
              </li>
              <li className={styles.link}>
                <Link to="/coachs" onClick={() => setMenuOpen(false)}>Vos coachs</Link>
              </li>
              <li className={styles.link}>
                <Link to="/collaborateurs" onClick={() => setMenuOpen(false)}>Collaborateurs</Link>
              </li>
              <li className={styles.link}>
                <Link to="/contact" onClick={() => setMenuOpen(false)}>Nous contacter</Link>
              </li>
            </ul>
          )}
          <Link className={styles.button} to="/reservation" onClick={() => setMenuOpen(false)}>
            <img src={arrow} alt="" />
            Réserver une séance
          </Link>
        </div>
      </aside>
    </>
  );
};

export default Navbar;
