import { Link } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import Popup from 'react-customizable-popup';
import useAuth from '../../lib/hooks/useAuth';
import useProtected from '../../lib/hooks/useProtected';
import edit from '../../icons/edit.svg';
import plus from '../../icons/plus.svg';
import cross from '../../icons/cross.svg';
import styles from '../../styles/pages/Liste-back.module.scss';

const ListeCoachs = () => {
  useProtected();
  const [coachs, setCoachs] = useState([]);
  const [currentCoach, setCurrentCoach] = useState({});
  const popupRef = useRef(null);
  const { user } = useAuth();

  const parseDate = (date) => {
    if (!date) {
      return {};
    }
    const newDate = new Date(date);
    let month = newDate.getMonth() + 1;
    let day = newDate.getDate();
    const year = newDate.getFullYear();

    if (month < 10) {
      month = `0${month}`;
    }
    if (day < 10) {
      day = `0${day}`;
    }

    return `${day}/${month}/${year}`;
  };

  const fetchData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_REQUEST_URL}/coaches`,
      {
        method: 'GET',
      },
    );
    if (response.ok) {
      const data = await response.json();
      setCoachs(data['hydra:member']);
    }
  };

  const handleClickCoach = (coach) => {
    popupRef.current.open();
    setCurrentCoach({
      id: coach.id,
      email: coach.email,
      lname: coach.lname,
      fname: coach.fname,
      address: coach.address,
      birthdate: parseDate(coach.birthDate),
      phone: coach.phone,
      photo: coach.photo,
      description: coach.description,
      seances: coach.seances,
      studios: coach.studios,
      roles: coach.roles,
    });
  };

  const handleDelete = async () => {
    const yes = confirm('Êtes-vous sûr de vouloir supprimer ce coach ? Toutes ses informations seront perdues.');
    if (yes) {
      const response = await fetch(`${process.env.REACT_APP_REQUEST_URL}/coaches/${currentCoach.id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${user.token}`,
        },
      });
      if (response.ok) {
        fetchData();
        popupRef.current.close();
        alert('Ce coach a bien été supprimé.');
      } else {
        alert('Une erreur est survenue.');
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <header className={styles.header}>
        <div>
          <h1 className={styles.titre}>Liste des coachs</h1>
        </div>
      </header>
      <table className={styles.tableau}>
        <thead className={styles.tableau__header}>
          <tr>
            <th className={styles.tableau__entete}> </th>
            <th className={styles.tableau__entete}>Nom</th>
            <th className={styles.tableau__entete}>Prénom</th>
            <th className={`${styles.tableau__entete} ${styles.tableau__mobile}`}>Email</th>
            <th className={`${styles.tableau__entete} ${styles.tableau__mobile}`}>Téléphone</th>
            <th className={`${styles.tableau__entete} ${styles.tableau__mobile}`}>Date de naissance</th>
            <th className={`${styles.tableau__entete} ${styles.tableau__mobile}`}>Adresse</th>
            <th className={`${styles.tableau__entete} ${styles.tableau__desktop}`}> </th>
          </tr>
        </thead>
        <tbody className={styles.tableau__contenant}>
          { coachs.map((coach) => (
            <tr key={coach.id} onClick={() => handleClickCoach(coach)}>
              <td className={styles.tableau__contenu}><Link to={`/back/modification-coach/${coach.id}`}><img src={edit} alt="editer" className={styles.icone} /></Link></td>
              <td className={styles.tableau__contenu}>{coach.lname}</td>
              <td className={styles.tableau__contenu}>{coach.fname}</td>
              <td className={`${styles.tableau__contenu} ${styles.tableau__mobile}`}>{coach.email}</td>
              <td className={`${styles.tableau__contenu} ${styles.tableau__mobile}`}>{coach.phone}</td>
              <td className={`${styles.tableau__contenu} ${styles.tableau__mobile}`}>{parseDate(coach.birthDate)}</td>
              <td className={`${styles.tableau__contenu} ${styles.tableau__mobile}`}>{coach.address}</td>
              <td className={`${styles.tableau__contenu} ${styles.tableau__desktop}`}><img src={plus} alt="editer" className={styles.icone} data-open /></td>
            </tr>
          ))}
        </tbody>
      </table>
      <aside className={styles.aside}>
        <Link to="/back/ajout-coach" className={styles.aside__button}>Ajouter un coach</Link>
      </aside>
      <Popup className={styles.popup} ref={popupRef} position="modal">
        <button className={styles.cross} type="button" data-close>
          <img src={cross} alt="" />
        </button>
        <h3 className={styles.title}>{currentCoach.fname} {currentCoach.lname}</h3>
        <p className={styles.text}>Email<span className={styles.bold}>{currentCoach.email}</span></p>
        <p className={styles.text}>Téléphone<span className={styles.bold}>{currentCoach.phone}</span></p>
        <p className={styles.text}>Date de naissance<span className={styles.bold}>{currentCoach.birthdate}</span></p>
        <p className={styles.text}>Adresse<span className={styles.bold}>{currentCoach.address}</span></p>
        <Link to={`/back/modification-coach/${currentCoach.id}`} className={styles.button}>Modifier les informations</Link>
        <button className={styles.deleteButton} type="button" onClick={handleDelete}>Supprimer le coach</button>
      </Popup>
    </>
  );
};

export default ListeCoachs;
