import { Link } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Popup from 'react-customizable-popup';
import useAuth from '../../lib/hooks/useAuth';
import useProtected from '../../lib/hooks/useProtected';
import parseDateWithSlashes from '../../lib/utils/parseDateWithSlashes';
import edit from '../../icons/edit.svg';
import plus from '../../icons/plus.svg';
import cross from '../../icons/cross.svg';
import searchIcon from '../../icons/search.svg';
import styles from '../../styles/pages/Liste-back.module.scss';

const ListeClients = () => {
  useProtected();
  const allClients = useRef([]);
  const searchInput = useRef(null);
  const [clients, setClients] = useState([]);
  const [search, setSearch] = useState('');
  const [currentClient, setCurrentClient] = useState({});
  const popupRef = useRef(null);
  const { user } = useAuth();

  const fetchData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_REQUEST_URL}/clients`,
      {
        method: 'GET',
      },
    );
    if (response.ok) {
      const data = await response.json();
      allClients.current = data['hydra:member'];
      setClients(data['hydra:member']);
    }
  };

  const handleClickClient = (client) => {
    popupRef.current.open();
    setCurrentClient({
      id: client.id,
      lname: client.lname,
      fname: client.fname,
      email: client.email,
      phone: client.phone,
      birthdate: parseDateWithSlashes(client.birthDate),
      address: client.address,
      credits: client.credits,
      creditsOne: client.creditsOne,
      card: client.card,
      isVerified: client.isVerified,
    });
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    setClients(allClients.current.filter((client) => (
      client
        .lname
        .toLowerCase()
        .includes(event.target.value)
      || client
        .fname
        .toLowerCase()
        .includes(event.target.value)
      || client
        .phone
        .toLowerCase()
        .includes(event.target.value)
    )));
  };

  const handleDelete = async () => {
    const yes = confirm('Êtes-vous sûr de vouloir supprimer ce client ? Toutes ses informations, ses réservations et son nombre de crédits seront perdus.');
    if (yes) {
      const response = await fetch(`${process.env.REACT_APP_REQUEST_URL}/clients/${currentClient.id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${user.token}`,
        },
      });
      if (response.ok) {
        fetchData();
        popupRef.current.close();
        alert('Ce client a bien été supprimé.');
      } else {
        alert('Une erreur est survenue.');
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>One2One | Liste des clients</title>
        <meta name="description" content="Ici sont listés tous les clients de la salle de sport One2One, les coachs peuvent modifier les données de client en cliquant sur l’icône éditer." />
      </Helmet>
      <header className={styles.header}>
        <div>
          <h1 className={styles.titre}>Liste des clients</h1>
        </div>
      </header>
      <section className={styles.searchContainer} onClick={() => searchInput.current.focus()} role="button" aria-hidden>
        <img className={styles.icon} src={searchIcon} alt="" />
        <input
          className={styles.input}
          type="text"
          ref={searchInput}
          value={search}
          onChange={handleSearch}
          placeholder="Rechercher un client"
        />
      </section>
      <table className={styles.tableau}>
        <thead className={styles.tableau__header}>
          <tr>
            <th className={styles.tableau__entete}> </th>
            <th className={styles.tableau__entete}>Nom</th>
            <th className={styles.tableau__entete}>Prénom</th>
            <th className={`${styles.tableau__entete} ${styles.tableau__mobile}`}>Email</th>
            <th className={`${styles.tableau__entete} ${styles.tableau__mobile}`}>Téléphone</th>
            <th className={`${styles.tableau__entete} ${styles.tableau__mobile}`}>Date de naissance</th>
            <th className={`${styles.tableau__entete} ${styles.tableau__mobile}`}>Adresse</th>
            <th className={`${styles.tableau__entete} ${styles.tableau__mobile}`}>Séances restantes</th>
            <th className={`${styles.tableau__entete} ${styles.tableau__mobile}`}>Séances ONE</th>
            <th className={`${styles.tableau__entete} ${styles.tableau__mobile}`}>Numéro de carte</th>
            <th className={`${styles.tableau__entete} ${styles.tableau__mobile}`}>Compte activé ?</th>
            <th className={`${styles.tableau__entete} ${styles.tableau__desktop}`}> </th>
          </tr>
        </thead>
        <tbody className={styles.tableau__contenant}>
          { clients.map((client) => (
            <tr key={client.id} onClick={() => handleClickClient(client)}>
              <td className={styles.tableau__contenu}><Link to={`/back/modification-client/${client.id}`}><img src={edit} alt="editer" className={styles.icone} /></Link></td>
              <td className={styles.tableau__contenu}>{client.lname}</td>
              <td className={styles.tableau__contenu}>{client.fname}</td>
              <td className={`${styles.tableau__contenu} ${styles.tableau__mobile}`}>{client.email}</td>
              <td className={`${styles.tableau__contenu} ${styles.tableau__mobile}`}>{client.phone}</td>
              <td className={`${styles.tableau__contenu} ${styles.tableau__mobile}`}>{parseDateWithSlashes(client.birthDate)}</td>
              <td className={`${styles.tableau__contenu} ${styles.tableau__mobile}`}>{client.address}</td>
              <td className={`${styles.tableau__contenu} ${styles.tableau__mobile}`}>{client.credits}</td>
              <td className={`${styles.tableau__contenu} ${styles.tableau__mobile}`}>{client.creditsOne}</td>
              <td className={`${styles.tableau__contenu} ${styles.tableau__mobile}`}>{client.card}</td>
              <td className={`${styles.tableau__contenu} ${styles.tableau__mobile}`}>{client.isVerified ? 'Oui' : 'Non'}</td>
              <td className={`${styles.tableau__contenu} ${styles.tableau__desktop}`}><img src={plus} alt="editer" className={styles.icone} data-open /></td>
            </tr>
          ))}
        </tbody>
      </table>
      <aside className={styles.aside}>
        <Link to="/back/ajout-client" className={styles.aside__button}>Ajouter un client</Link>
      </aside>
      <Popup className={styles.popup} ref={popupRef} position="modal">
        <button className={styles.cross} type="button" data-close>
          <img src={cross} alt="" />
        </button>
        <h3 className={styles.title}>{currentClient.fname} {currentClient.lname}</h3>
        <p className={styles.text}>Email<span className={styles.bold}>{currentClient.email}</span></p>
        <p className={styles.text}>Téléphone<span className={styles.bold}>{currentClient.phone}</span></p>
        <p className={styles.text}>Date de naissance<span className={styles.bold}>{currentClient.birthdate}</span></p>
        <p className={styles.text}>Adresse<span className={styles.bold}>{currentClient.address}</span></p>
        <p className={styles.text}>Crédits restants<span className={styles.bold}>{currentClient.credits}</span></p>
        <p className={styles.text}>Crédits ONE<span className={styles.bold}>{currentClient.creditsOne}</span></p>
        <p className={styles.text}>Numéro de carte<span className={styles.bold}>{currentClient.card}</span></p>
        <p className={styles.text}>Compte activé ?<span className={styles.bold}>{currentClient.isVerified ? 'Oui' : 'Non'}</span></p>
        <Link to={`/back/modification-client/${currentClient.id}`} className={styles.button}>Modifier les informations</Link>
        <button className={styles.deleteButton} type="button" onClick={handleDelete}>Supprimer le client</button>
      </Popup>
    </>
  );
};

export default ListeClients;
