import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Studios from './components/Common/Studios/Studios';
import useAuth from './lib/hooks/useAuth';
import imageAccueil from './images/one2one-accueil.jpg';
import fullLogo from './icons/full-logo.svg';
import people from './icons/people.svg';
import pen from './icons/pen.svg';
import mobile from './icons/mobile.svg';
import spivi from './images/spivi.png';
import video from './assets/bestaven.mp4';
import bestaven from './images/bestaven.jpg';
import logoCoq from './images/logo-maitre-coq.png';
import styles from './styles/pages/Home.module.scss';

const App = () => {
  const { user } = useAuth();

  useEffect(() => {
  }, []);

  const scrollTo = () => {
    window.scroll(0, window.innerHeight);
  };

  return (
    <>
      <Helmet>
        <title>One2One, votre club Sport Santé Bien-Être à la Rochelle</title>
        <meta name="description" content="Profitez de cours de fitness sur mesure, personnalisés et parfaitement équipés en compagnie de Pascal et son équipe à One2One la Rochelle. Réservez en ligne !" />
      </Helmet>
      <header className={styles.header}>
        <div className={styles.textContainer}>
          {/* <img className={styles.logoTitle} src={logo} alt="" /> */}
          <h2 className={styles.hero}><strong>One<span className={styles.pink}>2</span>One</strong></h2>
          <h3 className={styles.subhero}>votre <strong>club Sport</strong> Santé Bien-Être à <strong>la Rochelle</strong></h3>
          <div className={styles.buttonContainer}>
            <Link className={styles.button} to="/reservation">Réserver une séance</Link>
            <button className={styles.button} onClick={scrollTo} type="button">Découvrir</button>
          </div>
          {!user && (
            <p className={styles.gratuite}>Réservez votre séance d'essai gratuite ci-dessus !</p>
          )}
        </div>
        <div className={styles.imageContainer}>
          <div className={styles.image}>
            <img src={imageAccueil} alt="" />
            <div className={styles.gradient} />
          </div>
          <img className={styles.logo} src={fullLogo} alt="" />
        </div>
      </header>
      <section className={styles.section1}>
        <h2 className={styles.title}>Concept</h2>
        <ul className={styles.list}>
          <li>
            <figure className={styles.item}>
              <img className={styles.icon} src={people} alt="" />
              <figcaption>
                <h3 className={styles.smallTitle}>Accompagné</h3>
                <p className={styles.description}>
                  Découvrez nos <strong>Small Group Training</strong> (entraînement en effectif réduit), 100% coachés
                  où vous serez au centre de nos attentions.
                </p>
              </figcaption>
            </figure>
          </li>
          <li>
            <figure className={styles.item}>
              <img className={styles.icon} src={pen} alt="" />
              <figcaption>
                <h3 className={styles.smallTitle}>Personnalisé</h3>
                <p className={styles.description}>
                  Nous avons créé pour vous, <strong>3 studios totalement connectés</strong> exclusivement dédiés à
                  votre <strong>remise en forme</strong>.
                </p>
              </figcaption>
            </figure>
          </li>
          <li>
            <figure className={styles.item}>
              <img className={styles.icon} src={mobile} alt="" />
              <figcaption>
                <h3 className={styles.smallTitle}>Connecté</h3>
                <p className={styles.description}>
                  Des <strong>équipements de fitness</strong> hautement fonctionnels à votre service, avec le système
                  <strong>SPIVI</strong> dédié.
                </p>
              </figcaption>
            </figure>
          </li>
        </ul>
        <Link to="/concept" className={styles.button}>Découvrir le concept</Link>
      </section>
      <section className={styles.section2}>
        <Studios doors />
      </section>
      <section className={styles.section3}>
        <h2 className={styles.title}>One2One partenaire officiel Team Voile Maître Coq.<br />En charge de la préparation physique de <span className={styles.pink}>Yannick BESTAVEN</span>, vainqueur de l'édition 2020 du <span className={styles.pink}>Vendée Globe</span>.</h2>
        <div className={styles.bestavenContainer}>
          <img className={styles.image} src={bestaven} alt="" />
          <img className={styles.logo} src={logoCoq} alt="" />
        </div>
        <div className={styles.video}>
          <video className={styles.youtube} src={video} controls><track kind="captions" /></video>
        </div>
        <div className={styles.spivi}>
          <p className={styles.explicationSpivi}>
            SPiVi est une application destinée à tracer vos activités et vos niveaux de performance en direct pendant les séances : mesure de fréquence cardiaque, dépense calorique, capteur de puissance, ... Un moyen efficace de vous motiver à améliorer votre forme avec nous ! Allez sur le site SPiVi...
          </p>
          <a href="https://www.spivi.com/spivi-cgi/spivinet_login.cgi" className={styles.link} target="_blank" rel="noreferrer"><img src={spivi} alt="spivi" /></a>
        </div>
      </section>
    </>
  );
};

export default App;
