import bcrypt from 'bcryptjs';
import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import arrow from '../../icons/arrow-return.svg';
import useProtected from '../../lib/hooks/useProtected';
import styles from '../../styles/pages/Form-clients-back.module.scss';

const AjoutClientBack = () => {
  useProtected();
  const [client, setClient] = useState({});

  const navigate = useNavigate();

  const handleChange = (event) => {
    if (event.target.name === 'isVerified') {
      let isVerifiedValue = client.isVerified;
      if (event.target.value === '1') {
        isVerifiedValue = true;
      } else {
        isVerifiedValue = false;
      }
      setClient((state) => ({ ...state, [event.target.name]: isVerifiedValue }));
    } else {
      setClient((state) => ({ ...state, [event.target.name]: event.target.value }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const salt = bcrypt.genSaltSync(10);

    let generatedCard = Math.floor(10000000 + Math.random() * 90000000).toString(10);
    const fetchClient = async () => {
      const duplicate = await fetch(
        `${process.env.REACT_APP_REQUEST_URL}/clients?card=${generatedCard}`,
        {
          method: 'GET',
        },
      );
      if (duplicate.ok) {
        const responseDupl = await duplicate.json();
        if (responseDupl['hydra:member'].length > 0) {
          generatedCard = Math.floor(10000000 + Math.random() * 90000000).toString(10);
          fetchClient();
        }
      }
    };
    fetchClient();

    const hashedPassword = bcrypt.hashSync(generatedCard, salt);

    const body = JSON.stringify({
      email: client.email,
      lname: client.lname,
      fname: client.fname,
      address: client.address,
      birthDate: client.birthDate,
      phone: client.phone,
      password: hashedPassword,
      seances: [],
      credits: parseInt(client.credits, 10),
      creditsOne: parseInt(client.creditsOne, 10),
      isVerified: client.isVerified,
      card: generatedCard,
      roles: ['ROLE_USER'],
    });

    const response = await fetch(`${process.env.REACT_APP_REQUEST_URL}/clients`, {
      method: 'POST',
      body,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.ok) {
      const bodyMailClient = JSON.stringify({
        fname: client.fname,
        email: client.email,
        card: generatedCard,
      });
      const responseMailClient = await fetch(
        `${process.env.REACT_APP_MAIL_URL}/ajout-client`,
        {
          method: 'POST',
          body: bodyMailClient,
        },
      );
      if (responseMailClient.ok) {
        alert('Le client que vous venez d\'ajouter a reçu un mail avec ses informations de connexion.');
      } else {
        alert('Une erreur s\'est produite lors de l\'envoi du mail au client.');
      }
      navigate('/back/liste-clients');
    }
  };

  return (
    <>
      <Helmet>
        <title>One2One | Ajouter un client</title>
        <meta name="description" content="Il est possible d’ajouter un client en renseignant son nom, son prénom, sa date de naissance, son email mais aussi son numéro de téléphone et son adresse." />
      </Helmet>
      <Link to="/back/liste-clients" className={styles.lien}>
        <img src={arrow} alt="arrow" className={styles.image} />
        <p>Retourner à la liste des clients</p>
      </Link>
      <h1 className={styles.titre}>Ajout d&apos;un nouveau compte client</h1>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.nomComplet}>
          <label className={styles.label} htmlFor="lname">
            <p>Nom</p>
            <input className={styles.champ} type="text" name="lname" onChange={handleChange} required />
          </label>
          <label className={styles.label} htmlFor="fname">
            <p>Prénom</p>
            <input className={styles.champ} type="text" name="fname" onChange={handleChange} required />
          </label>
        </div>
        <div className={styles.naissanceTelephone}>
          <label className={styles.label} htmlFor="birthDate">
            <p>Date de naissance</p>
            <input className={styles.champ} type="date" name="birthDate" onChange={handleChange} required />
          </label>
          <label className={styles.label} htmlFor="phone">
            <p>Numéro de téléphone</p>
            <input className={styles.champ} type="text" name="phone" onChange={handleChange} required />
          </label>
        </div>
        <div className={styles.adresse}>
          <label className={styles.label} htmlFor="address">
            <p>Adresse</p>
            <input className={styles.champ} type="text" name="address" onChange={handleChange} required />
          </label>
        </div>
        <div className={styles.credits}>
          <label className={styles.label} htmlFor="email">
            <p>Email</p>
            <input className={styles.champ} type="email" name="email" onChange={handleChange} required />
          </label>
          <label className={styles.label} htmlFor="isVerified">
            <p>Compte activé ?</p>
            <select className={styles.champ} name="isVerified" onChange={handleChange}>
              <option>Choisir</option>
              <option value="1">Oui</option>
              <option value="0">Non</option>
            </select>
          </label>
        </div>
        <div className={styles.credits}>
          <label className={styles.label} htmlFor="credits">
            <p>Nombre de séances sur la carte</p>
            <input className={styles.champ} type="number" name="credits" min="0" onChange={handleChange} required />
          </label>
          <label className={styles.label} htmlFor="creditsOne">
            <p>Nombre de séances ONE</p>
            <input className={styles.champ} type="number" name="creditsOne" min="0" onChange={handleChange} required />
          </label>
        </div>
        <input className={styles.button} type="submit" />
      </form>
    </>
  );
};
export default AjoutClientBack;
