import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import biking from '../images/biking-free.jpg';
import biking2 from '../images/biking-activite.jpeg';
import cross from '../images/cross.jpg';
import cross2 from '../images/cross-training-activite.jpeg';
import wellness from '../images/wellness.jpg';
import wellness2 from '../images/wellness-activite.jpg';
import logoBiking from '../images/studio-biking.png';
import logoCrossTraining from '../images/studio-cross-training.png';
import logoWellness from '../images/studio-wellness.png';
import styles from '../styles/pages/Activite.module.scss';

const Activite = () => {
  const params = useParams();

  const [studio, setStudio] = useState('');

  useEffect(() => {
    setStudio(params.name);
  }, []);

  return (
    <>
      <Helmet>
        <title>One2One | Nos studios personnalisés pour vos entraînement</title>
        <meta name="description" content="One2One vous propose les trois studios permettant de répondre à vos besoins. Du Biking 2.0 au Cross-training en passant par le Wellness, One2One vous accueil dans ses studios personnalisés avec du matériel de qualité." />
      </Helmet>
      {(studio === 'cross-training') && (
      <section className={styles.section}>
        <figure className={styles.figure}>
          <img className={styles.image} src={cross} alt="" />
          <div className={styles.gradient} />
          <figcaption className={styles.content}>
            <img className={styles.logo} src={logoCrossTraining} alt="" />
          </figcaption>
        </figure>
        <h1 className={styles.title}><span className={styles.bleu}>Cross</span> training 2.O</h1>
        <h2 className={styles.slogan}><span className={styles.pink}>Dépensez-vous</span> dans un espace connecté.</h2>
        <ul className={styles.list}>
          <li className={styles.item}>
            <h3 className={styles.subtitle}>Descriptif</h3>
            <p className={styles.text}>Profitez d'un espace dédié, d'équipements spécifiques, d'un coach à votre service pour vivre une séance d'entrainement mixant cardio et renforcement musculaire.</p>
            <p className={styles.text}>Une expérience 100% coachée ET 100% connectée, grâce au système exclusif SpiVi.</p>
            <p className={styles.text}>Plus de 20 séances par semaine du lundi au dimanche inclus. Une version du circuit training HIIT (High Interval Intensity Training) en "small group" de 1 à 6 participants, plus raisonnée et équilibrée que le cross-fit, pour une approche globale de la condition physique.</p>
          </li>
          <li className={styles.item}>
            <h3 className={styles.subtitle}>Matériel</h3>
            <p className={styles.text}>Profitez d'un espace dédié et d'équipements spécifiques.</p>
            <p className={styles.text}> Votre coach vous accueille dans un studio aux équipements permettant une infinité d’exercices hyper innovants pour un entrainement complet tant d’un point de vue cardio que musculaire.</p>
          </li>
          <li className={styles.item}>
            <h3 className={styles.subtitle}>Partenaire</h3>
            <p className={styles.text}>Un briefing et un temps d’échauffement pour vous donner l’objectif de votre session, vous expliquer les 6 à 8 circuits, vous donner les bonnes postures et conseils techniques pour travailler efficacement sans vous blesser, puis top départ pour votre session en totale connexion avec le système SPiVi.</p>
          </li>
        </ul>
        <Link className={styles.button} to="/reservation">Réservez votre séance</Link>
        <img className={styles.bottomImage} src={cross2} alt="" />
      </section>
      )}
      {(studio === 'biking') && (
        <section className={styles.section}>
          <figure className={styles.figure}>
            <img className={styles.image} src={biking} alt="" />
            <div className={styles.gradient} />
            <figcaption className={styles.content}>
              <img className={styles.logo} src={logoBiking} alt="" />
            </figcaption>
          </figure>
          <h1 className={styles.title}><span className={styles.jaune}>Biking</span> 2.0</h1>
          <h2 className={styles.slogan}>Roulez dans un <span className={styles.pink}>espace</span> connecté.</h2>
          <ul className={styles.list}>
            <li className={styles.item}>
              <h3 className={styles.subtitle}>Descriptif</h3>
              <p className={styles.text}>Un Studio 100% connecté et innovant transformant les cours de biking classiques en une expérience ludique et interactive.</p>
            </li>
            <li className={styles.item}>
              <h3 className={styles.subtitle}>Matériel</h3>
              <p className={styles.text}>8 vélos spinning dernière génération LIFFITNESS équipées du système Motion Color avec capteur de Fréquence Cardiaque, Capteur de Cadence, Capteur de Puissance, Test FTP, ... </p>
              <p className={styles.text}>Une sono au top pour vous faire vibrer...</p>
              <p className={styles.text}>Après avoir pris en main vos vélos : plongez en immersion dans un univers connecté pour une session puissante, survoltée et ludique.</p>
              <p className={styles.text}>Vous vivrez un programme de course varié où vous alternerez des challenges individuels ou collectifs en défiant le coach.</p>
              <p className={styles.text}>Des playlists inédites et énergisante viennent accompagner ce cours totalement explosif !</p>
              <p className={styles.text}>C'est le cours qui vous fera brûler un maximum de calories !</p>
            </li>
            <li className={styles.item}>
              <h3 className={styles.subtitle}>Partenaire</h3>
              <p className={styles.text}>Le système 3D de simulation SPiVi permet une immersion totale des participants pour encore plus de motivation et de résultats.</p>
            </li>
          </ul>
          <Link className={styles.button} to="/reservation">Réservez votre séance</Link>
          <img className={styles.bottomImage} src={biking2} alt="" />
        </section>
      )}
      {(studio === 'wellness') && (
        <section className={styles.section}>
          <figure className={styles.figure}>
            <img className={styles.image} src={wellness} alt="" />
            <div className={styles.gradient} />
            <figcaption className={styles.content}>
              <img className={styles.logo} src={logoWellness} alt="" />
            </figcaption>
          </figure>
          <h1 className={styles.title}><span className={styles.vert}>Wellness</span></h1>
          <h2 className={styles.slogan}>Prenez <span className={styles.pink}>soin de vous</span> dans un espace relaxant.</h2>
          <ul className={styles.list}>
            <li className={styles.item}>
              <h3 className={styles.subtitle}>Descriptif</h3>
              <p className={styles.text}>Un Studio dédié au YOGA, PILATES, STRETCHING, ET MOBILITE.</p>
              <p className={styles.text}>Vivez une expérience ressourçante accompagné par nos coachs spécialiste de ces activités.</p>
            </li>
            <li className={styles.item}>
              <h3 className={styles.subtitle}>Matériel</h3>
              <p className={styles.text}>La pratique des activités WELNESS libère des tensions physique et mentales pour retrouver force et équilibre au quotidien et dans la pratique sportive.</p>
              <p className={styles.text}>Tapis, briques, sangles, swissball, rouleaux de massage vous permettront d'accompagner votre progression.</p>
            </li>
            <li className={styles.item}>
              <h3 className={styles.subtitle}>Partenaire</h3>
              <p className={styles.text}>Des activités wellness au service du sportif :</p>
              <p className={styles.text}>Affiner ses ressentis intérieurs pour améliorer la gestion de son activité sportive et atteindre ses objectifs !</p>
              <p className={styles.text}>Libérer son souffle, placer le relâchement dans l’effort et optimiser les performances !</p>
              <p className={styles.text}>Acquérir le geste juste qui respecte le corps : de la compréhension du schéma corporel à la posture idéale.</p>
            </li>
          </ul>
          <Link className={styles.button} to="/reservation">Réservez votre séance</Link>
          <img className={styles.bottomImage} src={wellness2} alt="" />
        </section>
      )}
    </>

  );
};

export default Activite;
