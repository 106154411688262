import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import styles from '../styles/pages/No-page-found.module.scss';

const PageNotFound = () => (
  <>
    <Helmet>
      <title>One2One, votre club Sport Santé Bien-Être à la Rochelle</title>
      <meta name="description" content="Profitez de cours de fitness sur mesure, personnalisés et parfaitement équipés en compagnie de Pascal et son équipe à One2One la Rochelle. Réservez en ligne !" />
    </Helmet>
    <div className={styles.content}>
      <div className={styles.anim}>
        <lottie-player src="https://assets4.lottiefiles.com/packages/lf20_jwlppoja.json" background="transparent" speed="1" loop autoplay />
      </div>
      <p className={styles.text}>Page introuvable</p>
      <Link className={styles.button} to="/">Retourner à l'accueil</Link>
    </div>

  </>
);

export default PageNotFound;
