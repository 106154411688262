import { useEffect, useState } from 'react';
import styles from './Line.module.scss';

const Line = () => {
  const [maskPos, setMaskPos] = useState(styles.top);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY < 100) {
        setMaskPos(styles.top);
      } else if (window.scrollY + window.innerHeight > document.body.scrollHeight - 200) {
        setMaskPos(styles.bottom);
      } else {
        setMaskPos(styles.middle);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={styles.lineContainer}>
      <div className={`${styles.mask} ${maskPos}`} />
      <svg viewBox="0 0 998 5995" fill="none" preserveAspectRatio="xMidYMax meet">
        <path d="M498.001 0V751C498.001 962.5 10.6 1061.8 3 1355C-12.0082 1934 990.54 1775.5 994.501 2235C999.5 2815 413 2555.5 287.5 3049C162 3542.5 1011.5 3485.5 999.5 3948C987.5 4410.5 2 4550 2 4995.5C2 5441 524.5 5296.5 524.5 5995" stroke="#E62569" strokeWidth="4" />
      </svg>
    </div>
  );
};

export default Line;
